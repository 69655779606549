import ContextSystem from "./ContextSystem";

export const Names = {
  ProductImage: ["Termékkép", "Product image"],
  ShortDailyNumber: ["Napi azonosító", "Daily number"],
  CoverImagePC: ["Számítógépes nézet", "PC mode"],
  CoverImageMobile: ["Mobilos nézet", "Mobile mode"],
  ChangeCoverImages: ["Üzlet borítóképének módosítása", "Change cover image of this shop"],
  OtherCommentForGuest: ["Egyéb megjegyzés a vendég felé...", "Other comment for the guest..."],
  eg: ["pl.", "eg."],
  minutes: ["perc", "minute(s)"],
  PleaseChooseAnOptionFirst: ["Kérlek válassz egyet előbb.", "Please choose an option first."],
  ChooseDeliveryTime: ["Mikor szállítjuk ki a vendéghez?", "When is the delivery expected?"],
  ChoosePreparationTime: ["Mikor lesz elkészítve?", "When is the preparation is expected?"],
  AddComment: ["Megjegyzés hozzáfűzése", "Add comment"],
  Other: ["Egyéb", "Other"],
  Approx: ["Kb", "Approx"],
  DeliveryOrTakeawayShouldBeEnabled: ["Házhozszállítást vagy személyes átvételt (legalább egyet) engedélyezni kell.", "You need to enable at least one of two: Delivery, Takeaway."],
  DefaultVersion: ["Alap verzió", "Default version"],
  NoShopsConnectedToYourProfile: ["Nincs egy üzlet sem ezen a profilon.", "No shop or restaurant is available for this profile."],
  NoImageUploaded: ["Kérlek tölts fel egy képet.", "Upload an image."],
  NoImageUploadedErrorText: ["Kérlek tölts fel egy képet.", "Upload an image first"],
  UploadImage: ["Kép feltöltése", "Upload image"],
  SelectFile: ["Fájl kiválasztása", "Select a file"],
  CropImageText: ["Kép kivágása (? arány)", "Crop image (? ratio)"],
  ExtraSelectorTitle: ["Rakd össze a feltéteket", "Select toppings"],
  ExtraIsAlreadyInList: ["Ez a feltét már szerepel a listában.", "This topping is already listed."],
  MinEGQtyBelowZero: ["Min. feltétek száma nem lehet 0 alatt.", "Min. toppings' number shouldn't be below 0."],
  MaxEGQtyBelowZero: ["Max. feltétek száma nem lehet 0 alatt.", "Max. toppings' number shouldn't be below 0."],
  FreeEGQtyBelowZero: ["Ingyenes feltétek száma nem lehet 0 alatt.", "Free toppings' number shouldn't be below 0."],
  Max5ProductVersion: ["Maximum 5 termékváltozat lehet!", "You can have at most 5 product versions."],
  YouNeedAtLeastOneProductVersion: ["Legalább egy termékváltozatnak lennie kell!", "You need at least one product version."],
  ProductVersionPriceNonNegative: ["Termékváltozat ára legalább 0!", "Product version's price shouldn't be below 0."],
  CopyFromOtherProduct: ["Másolás más termékről", "Copy from another product"],
  SearchForProduct: ["Termék keresése...", "Search for product..."],
  NoProductToShow: ["Nincs kiválasztható termék", "No product to show"],
  VersionName: ["Verzió neve", "Version name"],
  ToppingGroupName: ["Feltétcsoport neve", "Topping group name"],
  EnterAGroupName: ["Írj be egy csoportnevet...", "Enter a group name..."],
  Min: ["Min", "Min"],
  Max: ["Max", "Max"],
  BasePriceContains: ["Alapár tartalmazza", "Base price contains"],
  ToppingName: ["Feltét neve", "Topping name"],
  MaxQuantity: ["Max. mennyiség", "Max. quantity"],
  Quantity: ["Mennyiség", "Quantity"],
  ShowingName: ["Megjelenített név", "Showing name"],
  AddTopping: ["Feltét hozzáadása", "Add topping"],
  SearchToppings: ["Feltét keresése...", "Search for topping..."],
  NoToppingsToShow: ["Nincs kiválasztható feltét", "No toppings to show"],
  AddNewTopping3Dots: ["Új feltét létrehozása...", "Add new topping..."],
  And: ["és", "and"],
  NoExtrasOnSelectedProduct: ["A kiválasztott terméken nincsenek feltétek vagy termékverziók, így nem lehet róla másolni.", "There is nothing to copy from the selected product."],
  ShallWeContinue: ["Folytatjuk?", "Shall we continue?"],
  ExtraNameTooShort: ["A feltét neve túl rövid", "The name of the topping is too short."],
  PriceShouldBeNonNegative: ["Az ár nem lehet 0-nál kisebb.", "The price shouldn't be below 0"],
  NewExtra: ["Új feltét", "New topping"],
  ModifyExtra: ["Feltét módosítása", "Modify topping"],
  ContractUnderProcessText: ["Már van egy folyamatban lévő vagy hatályos szerződés!", "You already have a contract in progress or a valid contract!"],
  CheckContract: ["Szerződés megtekintése", "Check contract"],
  Date: ["Dátum", "Date"],
  SignedBy: ["Aláírta", "Signed by"],
  BankAccountNumber: ["Bankszámlaszám", "Bank account number"],
  BankName: ["Bank neve", "Name of bank"],
  CompanyHQ: ["Cég székhelye", "Company address"],
  CompanyName: ["Cégnév", "Company name"],
  CompanyVAT: ["Cég adószáma", "Company VAT number"],
  ContractStateError: ["Hibás", "Failed"],
  ContractStateStopped: ["Bontva", "Terminated"],
  ContractStateDeclined: ["Szerződés elutasítva", "Declined"],
  ContractStateValid: ["Hatályos", "Valid"],
  ContractStateNew: ["Új szerződés", "New contract"],
  NoContractsYetText: ["Még nincs szerződés a rendszerben. Létrehozunk egyet?", "I couldn't find any contracts in the system. Shall we start one?"],
  OnlyEmptyCategoryCanBeRemoved: ["Csak üres kategóriát lehet törölni!", "You can only remove empty categories."],
  YouNeedMinOneCategory: ["Legalább 1 kategóriának kell lennie!", "You need at least one category."],
  Max2LevelCategories: ["Legfeljebb 2 szintű kategóriarendszer lehet a vendégek kényelmes böngészése miatt.", "You can only define 2 levels of subcategories, so consumers find it easy to use."],
  Max6SubCategories: ["Főkategórián belül legfeljebb 6 gyermekkategória lehet!", "Max numbers of second-level categories in a main category is 6"],
  Max6MainCategories: ["Legfeljebb 6 főkategória lehet!", "Max numbers of top level categories is 6."],
  ProductCategories: ["Termékkategóriák", "Product categories"],
  ConclusionOfContract: ["Szerződéskötés", "Conclusion Of Contract"],
  OrderReceivedTextPart1: ["", "You got "],
  OrderReceivedTextPart2: [" darab rendelésed érkezett! Igazold vissza az új rendelést!", " order(s)! Confirm them right away! ;)"],
  OrderReceived: ["Rendelés érkezett! - EnniAkarok", "Orders received! - EnniAkarok"],
  BrowserSettings: ["Böngésző beállítás", "Browser settings"],
  IncomingOrders: ["Bejövő rendelések", "Incoming orders"],
  AskPermissionForPushNotification: [
    (
      <>
        Szeretnénk rendszerértesítőt küldeni, amikor új rendelés érkezik.<br/>
        Kérlek engedélyezd az értesítés küldését, hogy minél gyorsabban tudjuk kiszolgálni a vendégeket!<br/>
        <br/>
        Köszönöm!
      </>
    ),
    (
      <>
        We would like to send you a system notification when a new order arrives. <br/>
        Please allow the notification to be sent so we can serve your costumers as quickly as possible! <br/>
        <br/>
        Thanks!
      </>
    )
  ], NoPermissionForPushNotification: [
    (
      <>
        Nem adtad meg az engedélyt, hogy értesítést tudjunk küldeni számodra.<br/>
        Ha szeretnél rendszerszintű értesítést kapni a bejövő rendelésekről, kérlek engedélyezd a böngésző
        beállításai között.
      </>
    ),
    (
      <>
        You have not given permission to send a browser notification. <br/>
        If you would like to receive system-wide notifications of incoming orders, please enable your browser.
        settings.
      </>
    )
  ],
  RestaurantPageText: ["Étteremoldal", "Restaurant page"],
  ActivateBtnText: ["Étterem aktiválása", "Activate restaurant"],
  ActivateTitle: ["Üdvözöllek az EnniAkarok.hu partner oldalán!", "Welcome to the partner page of EnniAkarok!"],
  ActivateDesc: ["Kérlek adj meg egy új jelszót, hogy később be tudj lépni az adminisztrációs oldaladra!", "Please add a new password, which you will use to log in to this page."],
  EnniAkarokActivatePageTitle: ["EnniAkarok Partner Aktiválás", "EnniAkarok Partner Activate"],
  EnniAkarokActivatePageDesc: ["Partner oldal aktiválás", "Partner page activation"],
  ErrorPage404Text: [(<>Ooupsz! :( Az oldal nem található!<br/>404-es hiba</>), (<>Ooo... :( Can't find this page<br/>Error
    404.</>)],
  ChangePassword: ["Jelszó megváltoztatása", "Change password"],
  CurrentPassword: ["Jelenlegi jelszó", "Current password"],
  PayingCycleValuePart1: ["", "Every "],
  PayingCycleValuePart2: ["napos", "day"],
  NotGiven: ["Nincs megadva", "Not given"],
  PaymentMethodIsNotSet: ["Nincs fizetési mód beállítva", "Payment method is not set up"],
  GivenPasswordsTooShort: ["A megadott jelszavak túl rövidek", "The given passwords are too short."],
  Categories: ["Kategóriák", "Categories"],
  Save: ["Mentés", "Save"],
  Saved: ["Mentve", "Saved"],
  Category: ["Kategória", "Category"],
  Details: ["Részletek", "Details"],
  Ok: ["Ok", "Ok"],
  ProductInsideMenuTextPart1: ["Ez a termék a", "This product is part of menu: "],
  ProductInsideMenuTextPart2: ["menü része, így nem törölheted! Előbb töröld a menüt.", "Please remove the menu first."],
  DeletedProductErrorText: ["Hiba, törölt termék", "Error, removed product"],
  ProductIsNotRemovable: ["Termék nem törölhető", "Product is not removable"],
  PCS: ["db", "pcs"],
  Print: ["Nyomtatás", "Print"],
  Accepted: ["Átvette", "Accepted"],
  Delivered: ["Kiszállítva", "Delivered"],
  NotAccepted: ["Nem vette át", "Not accepted"],
  AtShipping: ["Szállítás alatt", "At shipping"],
  Shipping: ["Szállítás", "Shipping"],
  Done: ["Kész", "Done"],
  Preparing: ["Készül", "Preparing"],
  Confirm: ["Visszaigazolás", "Confirm"],
  Decline: ["Elutasítás", "Decline"],
  Restore: ["Visszaállítás", "Restore"],
  NoActionsAvailable: ["Nincs elérhető művelet", "No actions available"],
  Actions: ["Műveletek", "Actions"],
  Comment: ["Megjegyzés", "Comment"],
  AddressCantBeLoaded: ["Cím nem betölthető", "Address can't be loaded"],
  Export: ["Export", "Export"],
  Search: ["Keresés", "Search"],
  StartDate: ["Kezdő dátum", "Date from"],
  EndDate: ["Végső dátum", "Date to"],
  Filters: ["Szűrők", "Filters"],
  NewOrders: ["Új rendelések", "New orders"],
  DoneOrders: ["Kész rendelések", "Done orders"],
  ConfirmedOrders: ["Folyamatban", "In progress"],
  Total: ["Végösszeg", "Total"],
  State: ["Állapot", "State"],
  OrderIDText: ["Rendelés azonosító", "Order number"],
  ProfileIDText: ["Felhasználói azonosító", "Profile ID"],
  NoOrders: ["Nincs rendelés", "There is no order"],
  PaymentMethods: ["Fizetési módok", "Payment methods"],
  PaymentMethod: ["Fizetési mód", "Payment method"],
  PaymentMethod3Dot: ["Fizetési mód...", "Payment method..."],
  SZEP_ONLINE_MKB: ["Online SZÉP kártya (MKB)", "SZÉP card online (MKB)"],
  SZEP_ONLINE_OTP: ["Online SZÉP kártya (OTP)", "SZÉP card online (OTP)"],
  SZEP_ONLINE_KH: ["Online SZÉP kártya (K&H)", "SZÉP card online (K&H)"],
  CREDITCARD_ONLINE: ["Online bankkártya", "Credit card"],
  COD_SZEP_KH: ["Helyszíni Szép kártya (K&H)", "Szép card on delivery (K&H)"],
  COD_SZEP_OTP: ["Helyszíni Szép kártya (OTP)", "Szép card on delivery (OTP)"],
  COD_SZEP_MKB: ["Helyszíni Szép kártya (MKB)", "Szép card on delivery (MKB)"],
  CODCredit: ["Helyszíni bankkártya", "Credit card on delivery"],
  Cash: ["Készpénz", "Cash"],
  CashBigBill: ["Készpénz (nagy címlet)", "Cash (big bill)"],
  Delivery: ["Házhoz szállítás", "Delivery"],
  ShippingMode: ["Szállítási mód", "Shipping mode"],
  ShippingMode3Dot: ["Szállítási mód...", "Shipping mode..."],
  OrderStateNew: ["Új", "New"],
  OrderStateConfirmed: ["Visszaigazolva", "Confirmed"],
  OrderStateDeclined: ["Elutasítva", "Declined"],
  OrderStateDeleted: ["Törölve", "Deleted"],
  OrderStateDone: ["Kész", "Done"],
  OrderStateReady: ["Elkészítve", "Ready"],
  OrderState3Dot: ["Állapot...", "State..."],
  OrderStateFailedTrans: ["Meghiúsult", "Failed"],
  WaitingForOnlinePayment: ["Online fizetésre vár", "Waiting for online payment"],
  Copy: ["Másolás", "Copy"],
  Unhide: ["Megjelenítés", "Unhide"],
  Hide: ["Elrejtés", "Hide"],
  NewMenuBtnText: ["Új menü", "New menu"],
  RemovedFromVisibleMenus: ["Levéve az elérhető menük közül.", "Removed from visible menus."],
  SelectedVisibleMenus: ["Kiválasztott elérhető menük", "Selected visible menus"],
  ProductHiddenText: ["A termék már nem látható a vásárlók számára.", "Product is hidden."],
  ProductUnhiddenText: ["A termék újra látható a vásárlók számára.", "Product is visible."],
  OrderingSaved: ["Sorrend mentve", "Order saved"],
  ProductAddedToTheMenu: ["Felvéve az elérhető menük közé", "Product is added to the menu."],
  ProductIsAlreadyInMenu: ["A menü már szerepel a listában.", "This product is already in the menu."],
  Name: ["Név", "Name"],
  Price: ["Ár", "Price"],
  OpenBeforeWork: ["Étterem kezeléséhez előbb kapcsold át a nyitvatartást!", "Open shop before work."],
  CloseForToday: ["Mára bezárok", "Close for today"],
  OpenShop: ["Bolt nyitása", "Open shop"],
  Orders: ["Rendelések", "Orders"],
  FloorMap: ["Asztaltérkép", "FloorMap"],
  OpeningsAndShipping: ["Szállítás és nyitvatartás", "Opening and Shipping"],
  Settings: ["Beállítások", "Settings"],
  Integrations: ["Integrációk", "Integrations"],
  Products: ["Termékek", "Products"],
  Menus: ["Menük", "Menus"],
  Toppings: ["Feltétek", "Toppings"],
  Hidden: ["rejtett", "hidden"],
  Manager: ["Menedzser", "Manager"],
  Waiter: ["Pincér", "Waiter"],
  Dispatcher: ["Diszpécser", "Dispatcher"],
  Kitchen: ["Konyha", "Kitchen"],
  AMOrPM: ["De/Du", "AM / PM"],
  NoDaysToShow: ["Nincs megjeleníthető nap", "No days to show"],
  SelectADay: ["Kérem válasszon egy napot", "Please select a date"],
  StartingTimeIsAfterEndingPoint: ["Kezdeti idő nem lehet a zárási idő előtt", "The starting time should be before the ending time."],
  InvalidDataEntered: ["Érvénytelen adat", "The given data is not valid"],
  OpenHourOrCloseHourIsEmpty: ["Kérem adjon meg kezdeti és zárási időt!", "Please enter a starting and an ending time."],
  NoDiscountShippingPriceGiven: ["Adj meg egy kedvezményes szállítási díjat. Ingyenes esetén adja meg, hogy 0Ft a díj értéke. Ha nem szeretne kedvezményes szállítási díjat megadni, törölje a kedvezmény határ összegét.", "You forgot to put the discount shipping price. If it's free, please put 0. If you would like to disable discount price on this shipping area, please remove the discount min. cart value."],
  NoShippingPriceIsGiven: ["Adj meg egy szállítási díjat. Ingyenes esetén adjon meg 0 értéket.", "You forgot the put the price. If it's free, please put a 0."],
  MinCartValueIsNegative: ["Min. rendelési összeg nem lehet 0 alatti.", "Min. cart value should be 0 or a positive number."],
  SelectACity: ["Válassz ki egy várost!", "Select a city!"],
  DeleteShippingPriceConfirmDetails: ["Biztosan törlöd a szállítási területet?", "Are you sure you want to remove the shipping price?"],
  DeleteShippingPriceConfirmTitle: ["Szállítási terület törlése", "Remove shipping price"],
  DeliveryOpenHoursText: ["Kiszállítás ideje", "Delivery time"],
  AcceptOrderTakeAwayOpenHoursText: ["Rendelés felvétel (személyes átvételre)", "Accept orders (for takeaway)"],
  AcceptOrderDeliveryOpenHoursText: ["Rendelés felvétel (kiszállításra)", "Accept orders (for delivery)"],
  ShopOpenHours: ["Üzlet nyitvatartása", "Open hours for the shop"],
  TakeAway: ["Személyes átvétel", "Takeaway"],
  AcceptOrders: ["Rendelés átvételi beállítások", "Settings for order acceptance"],
  TakeAwayOption: ["Személyes átvétel lehetősége", "Option for takeaway"],
  DeliveryOption: ["Házhozszállítás lehetősége", "Option for delivery"],
  ShippingPrice: ["Szállítási díj (Ft)", "Shipping price (Ft)"],
  MinShippingCartValueText: ["Min. rendelési összeg a fenti területre (Ft)", "Min. cart value for the above area (Ft)"],
  MinShippingCartValueText2: ["Minimum rendelési összeg (Ft)", "Minimum cart value (Ft)"],
  MinShippingCartValueText3: ["Kedvezményes rendelésösszeg határa (Ft)", "Discount shipping price min. cart value (Ft)"],
  MinShippingCartValueText4Part1: ["Szállíási díj", "Shipping price over "],
  MinShippingCartValueText4Part2: ["Ft rendelés felett", "Ft cart value"],
  MinShippingCartValueText5: ["Kedvezményes szállítási díj (Ft)", "Discount shipping price (Ft)"],
  NoCitiesToShow: ["Nincs megjeleníthető város", "No cities to show"],
  NameOfTheCity: ["Város neve", "City name"],
  CustomShippingPriceText: ["Egyedi szállítási terület beállításához vedd fel a kapcsolatot az Ügyfélszolgálattal.", "For defining custom shipping areas, please call our customer service for partners."],
  MinCartValue: ["Min. rendelés összeg", "Min. cart value"],
  ShippingPriceOverText: ["felett", "and above"],
  ShippingPlaces: ["Szállítási területek", "Shipping Places"],
  AddButtonText: ["Hozzáadás", "Add"],
  MessengerNotificationNeedSetupDetails: ["A messenger kapcsolat élesítéséhez a további lépések hozzáadás után jelennek meg.", "To activate this Messenger notification channel, you need to take the below steps."],
  TheNotificationChannelIsActive: ["Az értesítési kapcsolat éles.", "The notification channel is active"],
  MessengerNotificationSetUpText1: ["Az értesítési csatorna beállításához kérlek küldd el a", "To set up this channel, please send"],
  MessengerNotificationSetUpText2: ["szöveget Botinak", "text to Boti,"],
  MessengerNotificationSetUpText3Link: ["EnniAkarok ChatBotnak itt", "the EnniAkarok ChatBot"],
  MessengerNotificationSetUpText4: ["Ha a fenti linken keresztül nem működik az élesítés, keress rá Messengerben az \"", "If the above link is not working, please search on Messenger for the account \""],
  MessengerNotificationSetUpText5: ["\" fiókra, és küldd el neki az alábbi üzenetet:", "\" and send this text:"],
  FirstName: ["Keresztnév", "First name"],
  LastName: ["Vezetéknév", "Last name"],
  SelectType: ["Válassz típust", "Select type"],
  SetUpNew: ["Új beállítása", "Set up a new one"],
  NotificationSettingAdded: ["Értesítés hozzáadva!", "Notification setting added successfully"],
  EmailAddressNotValid: ["Email cím helytelen!", "The given email address is not valid."],
  ErrorTitle: ["Hiba", "Error"],
  ErrorMessageText: ["Oldal nem található", "Page not found"],
  LoginTitle: ["Bejelentkezés", "Login"],
  LoginPasswordPlaceholderText: ["Jelszó", "Password"],
  LoginButtonText: ["Bejelentkezés", "Log in"],
  HistoryTitle: ["Történet", "History"],
  HistoryFromText: ["Forrás", "From"],
  HistoryToText: ["Cél", "To"],
  HistorySenderPlaceholderText: ["Feladó", "Sender"],
  HistoryRecipientPlaceholderText: ["Címzett", "Recipient"],
  HistoryCancelledText: ["Visszamondva", "Cancelled"],
  HistoryAddressPlaceholderText: ["Cím", "Address"],
  HistoryPacketStatus: [
    [
      "Új",
      "Folyamatban",
      "Futár kijelölve",
      "Futár a feladónál",
      "Felvéve",
      "Futár a címzettnél",
      "Csomag leadva",
      "Kész",
      "Késik a futár (felvétel)",
      "Leadási hiba",
      "Késik a futár (leadás)",
      "Meghiúsult",
    ],
    [
      "New",
      "Pending",
      "Courier assigned",
      "Courier arrived origin",
      "Picked up",
      "Courier arrived destination",
      "Dropped off",
      "Done",
      "Courier too late for pickup",
      "Drop off not possible",
      "Courier too late for drop off",
      "Cancelled",
    ],
  ],
  MapTitle: ["Térkép", "Map"],
  MapNotificationBarJob: ["Aktív munka", "Active job"],
  MapNotificationBarJobOffer: ["Aktív ajánlat", "Active job offer"],
  MapOnButtonText: ["ON", "ON"],
  MapOffButtonText: ["OFF", "OFF"],
  MapLastOfferText: ["Utolsó ajánlat", "Last offer"],
  MapCurrency: ["Ft", "$"],
  MonthNames: [
    ["Január", "Február", "Márcus", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Oktober", "November", "December"],
    ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  ],
  DayNames: [
    ["Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat", "Vasárnap"],
    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  ],
  MapHourText: ["óra", "hour(s)"],
  MapActiveDayText: ["aktív nap", "active day(s)"],
  MapNavigateButtonText: ["Indulás", "Navigate"],
  MapNavigateToNextButtonText: ["A következőhöz", "Navigate to next"],
  MapScanQrCodeText: ["QR kód beolvasása", "Scan QR Code"],
  MapOpenInMapButtonText: ["Térkép megnyitása", "Open in map"],
  MapCommentPlaceholderText: ["Megjegyzés", "Comment"],
  MapErrorButtonText: ["Hiba", "Error"],
  MapErrorTexts: [
    ["Késői felvéte", "Címzett nem található", "Cím nem található", "Megtagadta", "Egyéb"],
    ["Pickup too late", "Customer not found", "Address not found", "Declined", "Other"]
  ],
  MapErrorDescriptionPlaceholderText: ["Hiba leírás", "Error description"],
  Upload: ["Feltöltés", "Upload"],
  CancelButtonText: ["Mégsem", "Cancel"],
  SendButtonText: ["Küldés", "Send"],
  MapClearButtonText: ["Ürítés", "Clear"],
  MapJobOfferReceivedText: ["Munkaajánlat érkezett", "Job offer received"],
  MapTotalKmText: ["Összesen ", "Total "],
  MapCircaText: ["kb.", "ca."],
  MapCommissionText: ["Jutalék", "Commission"],
  MapFirstStopText: ["Első megálló", "First stop"],
  MapPackagesCountText: ["Csomagok száma", "Packages count"],
  ProfileTitle: ["Profil", "Profile"],
  ProfilePasswordChangeText: ["Jelszó módosítása", "Password change"],
  ProfileCurrentPasswordPlaceholderText: ["Jelenlegi jelszó", "Current password"],
  ProfileNewPasswordPlaceholderText: ["Új jelszó", "New password"],
  ProfileDataChangeText: ["Adatok módosítása", "Data change"],
  Added: ["Hozzáadva", "Added"],
  Modify: ["Módosítás", "Modify"],
  Modified: ["Módosítva", "Modified"],
  Remove: ["Törlés", "Remove"],
  Removed: ["Törölve", "Removed"],
  ProfileFirstNamePlaceholderText: ["Keresztnév", "First name"],
  ProfileLastNamePlaceholderText: ["Vezetéknév", "Last name"],
  ProfileSignOutButtonText: ["Kijelentkezés", "Sign out"],
  MoreTitle: ["Egyéb", "More"],
  MoreLanguageText: ["Nyelv", "Language"],
  SelectShopText: ["Üzlet", "Shop"],
  NoShopsToShow: ["Nincs üzlet", "No shops"],
  LayoutSelectText: ["Nézet", "Layout"],
  NoLanguagesToShow: ["Nincs megjeleníthető nyelv", "No lanaguage to show"],
  NoLayoutToShow: ["Nincs megjeleníthető nézet", "No layout to show"],
  MoreUsedMapText: ["Használt térkép", "Used map"],
  CompanyDetailsText: ["Cég részletek", "Company details"],
  MoreCompanyFormText: ["Kft.", "Ltd."],
  MoreVatNumberText: ["Adószám", "VAT Number"],
  MoreSupportText: ["Support", "Support"],
  MorePhoneNumberText: ["Telefonszám", "Phone number"],
  MoreAppVersionText: ["App verzió", "App version"],
  MiscEmailText: ["E-mail cím", "E-mail address"],
  MiscSaveButtonText: ["Mentés", "Save"],
  MiscMinuteUnit: ["p", "m"],
  MiscPieces: ["db", "pc"],
  CookieText: ["Weboldalunk használatával jóváhagyja a cookie-k használatát a Cookie-kkal kapcsolatos irányelv értelmében.", "By using our website, you consent to the use of cookies in accordance with the Cookie Policy."],
  Close: ["Bezárás", "Close"],
  LoginMetaTitle: ["EnniAkarok Partner Bejelentkezés", "EnniAkarok Partner Login"],
  LoginMetaContent: ["Partneroldal bejelentkezés", "Partner page login"],
  ForgotMyPassword: ["Elfelejtettem a jelszavam", "Forgot my password"],
  SendPasswordResetTitle: ["Jelszóemlékeztető küldése", "Send new password"],
  PasswordResetText: ["Kérlek add meg az email címedet. Ha nem emlékeszel rá, írj egy emailt, hogy tudjunk segíteni!", "Please enter your email address. If you don’t remember it, write an email to us so we can help out!"],
  Contract: ["Szerződés", "Contract"],
  Contracts: ["Szerződések", "Contracts"],
  PayingCycle: ["Számlázási ciklus", "Payment period"],
  PayoutCycle: ["Kiutalási ciklus", "Payout period"],
  CompanyNameAndVATNumber: ["Cég neve, adószáma", "Name and VAT number of the company"],
  CheckMyContracts: ["Szerződéseim megtekintése", "Check my contracts"],
  ContractContentTitle: ["EnniAkarok.hu - Partnerségi Megállapodás", "EnniAkarok.hu - Parter Contract"],
  StartContract: ["Szerződéskötés", "Start contract"],
  CreateNewCassaPointIntegrationButtonText: ["Új kapcsolat beállítása", "New CassaPoint Integration"],
  CassaPointDetails: ["CassaPoint integráció adatok:", "CassaPoint integartion details:"],
  CassaPointIntegrationDetails: ["Az integráció igényét (és egyben a Kliens nevet) a CassaPointtól kell igényelni, aminek az átfutási ideje 7-10 nap, költsége egyszeri 2.000Ft és további 10Ft/rendelés. A pontos inforációkért kérlek vedd fel a kapcsolatot a ToolSite / CassaPoint üzemeltetőjével.", "If you would like to integrate EnniAkarok with ToolSite/CassaPoint, you need to request an integration from ToolSite. The setup takes about 7-10 days of time and the fee is 2000Ft and additional 10Ft per transferred order. For more accurate information on the prices please contact ToolSite/CassaPoint. EnniAkarok takes no fees on this integration."],
  ClientNameText: ["Kliens név", "Client name"],
  New: ["Új", "New"],
  RemoveNotificationConfirmTitle: ["Értesítés törlése", "Remove notification"],
  RemoveNotificationConfirmDetails: ["Biztosan törlöd az értesítési beállítást?", "Are you sure you want to delete this notification setting?"],
  Yes: ["Igen", "Yes"],
  No: ["Nem", "No"],
  NotificationSettingDeletedDetails: ["Értesítési csatorna sikeresen törölve!", "Notification setting removed successfully!"],
  LastNameShortDetails: ["Vezetéknév túl rövid!", "The given last name is too short."],
  FirstNameShortDetails: ["Keresztnév túl rövid!", "The given first name is too short."],
  Notification: ["Értesítés", "Notification"],
  Notifications: ["Értesítések", "Notifications"],
  NotificationsDetails: ["Itt tudod néhány lépéssel beállítani, hogy az EnniAkarok milyen felületeken küldjön értesítéseket számodra.", "You can set up how you would like us to notify you."],
  ServerNotAvailable: ["Szerver nem elérhető.", "Server not available."],
};

export default class Language {
  static getName(nameArray: string[] | string[][], subIndex: number | undefined): string {
    if (nameArray === undefined || nameArray === null) {
      return undefined;
    }

    let value: any = nameArray[ContextSystem.language];
    if (!value) {
      value = nameArray[0];
    }

    if (subIndex !== undefined && subIndex !== null) {
      return value[subIndex];
    }

    return value;
  }
}
