import APICaller from "./APICaller";

function getKey(quite: boolean, cb: (res: { type: number, error: number, key: string | null })=>{}) {
  APICaller.ownFetch(quite, "POST", "/zadarma/key", {}, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export default {
  getKey,
};
