export default class ZadarmaCall {
  static callInput: HTMLInputElement;
  static callButtonUp: HTMLDivElement;
  static callButtonDown: HTMLDivElement;

  static init() {
    if (!this.callInput)
      this.callInput = document.getElementById("zdrm-webphone-phonenumber-input");
    if (!this.callButtonUp)
      this.callButtonUp = document.getElementsByClassName("zdrm-webphone-call-btn-up")[0];
    if (!this.callButtonDown)
      this.callButtonDown = document.getElementsByClassName("zdrm-webphone-call-btn-down")[0];
  }

  static setCallNumber(number: string) {
    this.init();
    if (this.callInput)
      this.callInput.value = number;
  }

  static callNumber() {
    this.init();
    if (this.callButtonUp)
      this.callButtonUp.click();
  }
}
