export default class ErrorMessage {
  static WRONG_EMAIL_OR_PASS: number = 1001;
  static PERMISSION_DENIED: number = 1002;
  static PARAMETER_NOT_VALID: number = 1003;
  static DATA_NOT_FOUND: number = 1004;
  static AMBIGUOUS: number = 1005;
  static UNEXPECTED_ERROR: number = 1006;
  static EMAIL_NOT_VALID: number = 1007;
  static BODY_NOT_PARSABLE: number = 1008;
  static TOKEN_NOT_VALID: number = 1009;
  static DATABASE_ERROR: number = 1010;
  static ILLEGAL_STATE: number = 1011;
  static EMAIL_REGISTERED: number = 1012;
  static SHIPPING_PRICE_NOT_FOUND: number = 1013;
  static RESTAURANT_NOT_FOUND: number = 1014;
  static RESTAURANT_CLOSED_FOR_TODAY: number = 1015;
  static RESTAURANT_CLOSED: number = 1016;
  static DOESNT_HIT_MINIMUM_ORDER: number = 1017;
  static PRODUCT_NOT_FOUND: number = 1018;
  static EXTRA_QTY_OVER_MAX: number = 1019;
  static CITY_NOT_FOUND: number = 1020;
  static MIXED_PRODUCTS: number = 1021;
  static ADDRESS_PERMISSION_DENIED: number = 1022;
  static ADDRESS_NOT_FOUND: number = 1023;
  static ZIPCODE_NOT_FOUND: number = 1024;
  static CURRENT_PASSWORD_INVALID: number = 1025;
  static NEW_PASSWORD_TOO_SHORT: number = 1026;
  static NO_SHIPPING_TO_THIS_ADDRESS: number = 1027;
  static ORDER_NOT_FOUND: number = 1028;
  static TOO_FREQUENT_REQUESTS: number = 1029;
  static FIRSTNAME_NOT_VALID: number = 1030;
  static LASTNAME_NOT_VALID: number = 1031;
  static TEL_NOT_VALID: number = 1032;
  static RESTAURANT_NAME_NOT_VALID: number = 1033;
  static CANT_DELETE_DATA_FOUND: number = 1034;
  static MENU_NOT_REMOVABLE: number = 1035;
  static PENDING_OR_VALID_CONTRACT_IS_AVAILABLE: number = 1036;
  static PROFILE_NOT_FOUND: number = 1037;
  static SIP_IS_NOT_AVAILABLE_FOR_THIS_USER: number = 1038;
}
