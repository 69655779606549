export default class EventSystem {
  static queue = {};
  static idCounter = 0;

  static events = {
    upload_image: 0,
    image_uploaded: 1,
    extras_changed: 2,
    authentication_changed: 3,
    edit_extra: 4,
    edit_menu: 5,
    menu_edited: 6,
    product_edited: 7,
    blogURLChanged: 8,
    urlChanged: 9,
    sideMenu: 10,
    edit_product: 11,
    order_comment_done: 12,
    select_extras: 13,
    extras_selected: 14,
    open_order_comment: 15,
    contextSystemChanged: 16,
    show_confirmation_modal: 17,
    edit_product_categories: 18,
    open_contracts_details: 19,
    open_add_contract_modal: 20,
    open_show_contract_modal: 21,

    SHOW_TOAST: 2000,
    JOB_CHANGED: 2001,
    JOB_OFFER_RECEIVED: 2002,
  };

  static publish(event, data) {
    if (typeof EventSystem.queue === "undefined") {
      return false;
    }
    if (Object.keys(EventSystem.queue).length > 0) {
      if (typeof EventSystem.queue[event] !== "undefined" && EventSystem.queue[event].length > 0)
        for (let a of EventSystem.queue[event]) a.callback(data);
    }
  }

  static unsubscribe(id) {
    let eventName;
    let index = -1;
    A: for (let event in EventSystem.queue) {
      let eventSubscribers = EventSystem.queue[event];
      for (let i in eventSubscribers) {
        if (eventSubscribers[i].id === id) {
          index = i;
          eventName = event;
          break A;
        }
      }
    }
    if (index >= 0) {
      EventSystem.queue[eventName].splice(index, 1);
    }
  }

  static subscribe(event, callback) {
    if (typeof EventSystem.queue[event] === "undefined") {
      EventSystem.queue[event] = [];
    }

    let id = EventSystem.idCounter;
    EventSystem.queue[event].push({callback, id});
    EventSystem.idCounter++;
    return id;
  }
}
