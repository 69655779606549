import {Profile} from "./Profile";
import {Address} from "./Address";

export type OrderStateComment = { message: string, minutes: number };

export class OrderState {
  static NEW: number = 1;
  static WAITING_FOR_ONLINE_PAYMENT: number = 2;
  static CONFIRMED: number = 3;
  static DECLINED: number = 4;
  static DELETED: number = 5;
  static READY: number = 6;
  static FAILED_TRANS: number = 7;
  static DONE: number = 8;
  static PREPARING: number = 9;

  id: number;
  enabled: boolean;
  comment: OrderStateComment;
  dateTime: Date;
  orderID: number;
  status: number;
  finished: boolean;
}

export class OrderExtra {
  id: number;
  enabled: boolean;
  name: string;
  partnerID: number;
  orderID: number;
  qty: number;
  price: number;
  orderProductID: number;
  extraID: number;
  extraGroupID: number;
  saleID: number;
  total: number;
}

export type OrderProductSorted = OrderProduct & { products: OrderProduct[] };

export class OrderProduct {
  id: number;
  enabled: boolean;
  name: string;
  details: string;
  image1: string;
  image2: string;
  image3: string;
  categoryID: number;
  qty: number;
  partnerID: number;
  offerID: number;
  menuID: number;
  orderID: number;
  price: number;
  extrasPrice: number;
  itemTotalPrice: number;
  extras: OrderExtra[];
  type: number;
  productIndex: number;
  productID: number;
  versionID: number;
}

export class Order {
  id: number;
  enabled: boolean;
  number: string;
  dailyNumber: string;
  comment: string;
  profile: Profile;
  productList: OrderProduct[];
  orderTotalPrice: number;
  date: Date;
  orderStates: OrderState[];
  lastState: OrderState; //calculated value, not represented in database!
  partnerID: number;
  shippingMethod: number;
  shippingPrice: number;
  paymentMethod: number;
  address: Address;
  shippingPriceID: number;
}
