const Config = {
  TEST: false,
  DEBUG: false,
  imageUrlBase: "https://admin.enniakarok.hu/data/img/",
  mobileMaxWidth: 1000,
  version: "2.12.26.1",
  APIBaseURL: "https://backend.enniakarok.hu/admin",
  imageUploadURL: "https://admin.enniakarok.hu/php/upload.php",
  frontendURL: "https://www.enniakarok.hu",
  zadarmaAPIKey2: "c177adb1817f606f409261dc10ef58b547cf0194c3c3c2a6a66f187b92e58a374fd3c59feedc5a277231766066c5c09a0bcdd9663f0cdf054900349d75380f97",
  zadarmaAPIKey: "4a661da802eb96fbd07f",
  zadarmaSIP: "251833-105",
  partnerFrontendURL: "https://partner.enniakarok.hu",
  wsURL: "wss://backend.enniakarok.hu/ws",
};

if (Config.TEST) {
  Config.imageUrlBase = "https://admin.test.enniakarok.hu/data/img/";
  Config.APIBaseURL = "https://backend.test.enniakarok.hu/admin";
  Config.imageUploadURL = "https://admin.test.enniakarok.hu/php/upload.php";
  Config.frontendURL = "https://test.enniakarok.hu";
  Config.partnerFrontendURL = "https://partner.test.enniakarok.hu";
  Config.wsURL = "wss://backend.test.enniakarok.hu/ws"
  if (Config.DEBUG) {
    Config.APIBaseURL = "http://localhost:3332/admin"
    Config.imageUploadURL = "http://localhost:80/admin";
    Config.frontendURL = "http://localhost:3000";
    Config.partnerFrontendURL = "http://localhost:3001";
    Config.wsURL = "ws://localhost:8001"
  }
} else if (Config.DEBUG) {
  Config.imageUrlBase = "https://admin.test.enniakarok.hu/data/img/";
  Config.APIBaseURL = "http://localhost:3333/admin";
  Config.imageUploadURL = "http://localhost:80/php/upload.php";
  Config.frontendURL = "https://localhost:3000";
  Config.partnerFrontendURL = "http://localhost:3001";
  Config.wsURL = "ws://localhost:8000"
}

Config.productImageUrlBase = Config.imageUrlBase + "product/";
Config.partnerImageUrlBase = Config.imageUrlBase + "partner/";

export default Config;
