import CrossEventType from "./CrossEventType";
import EventSystem from "../EventSystem";
import {AuthAPI} from "../api/AuthAPI";
import ContextSystem from "../ContextSystem";
import {Order} from "../../model/Order";
import {Product} from "../../model/Product";
import {City, ZipCode} from "../../model/Address";
import {Shop} from "../../model/Shop";

export type CrossEventDataBase = { type: number };
type HandlerType = (msg: CrossEventDataBase, type: number) => void;

type PartnerMessage = CrossEventDataBase & { partner: Shop };
type LoggedOutEventData = CrossEventDataBase & { id: number };
type ProductChangedEventData = CrossEventDataBase & { product: Product | null, removeID: number };
type OrdersChangedEventData =
  CrossEventDataBase
  & { orders: Order[], products: Product[], cities: City[], zipCodes: ZipCode[] };

// noinspection JSUnusedLocalSymbols
const HandleOrderChanged: HandlerType = (msg: OrdersChangedEventData, type: number) => {
  ContextSystem.mergeOrders(msg.orders, msg.products, msg.cities, msg.zipCodes);
};

// noinspection JSUnusedLocalSymbols
const HandleProductChanged: HandlerType = (msg: ProductChangedEventData, type: number) => {
  if(msg.removeID > 0)
    ContextSystem.removeProduct(msg.removeID);
  if (msg.product)
    ContextSystem.mergeProducts([msg.product]);
};

// noinspection JSUnusedLocalSymbols
const HandleLoggedOut: HandlerType = (msg: LoggedOutEventData, type: number) => {
  if (ContextSystem.profile.id !== msg.id)
    return;

  AuthAPI.logout();
};

const HandleShopOpenedClosed: HandlerType = (msg: PartnerMessage, type: number) => {
  EventSystem.publish(EventSystem.events.partnerOpened, {
    opened: type === CrossEventType.SHOP_OPENED,
    shop: msg.partner
  });
};

let crossEventHandlerTable: {
  [type: number]: HandlerType;
} = {};
crossEventHandlerTable[CrossEventType.ORDER_CHANGED] = HandleOrderChanged;
crossEventHandlerTable[CrossEventType.PRODUCT_CHANGED] = HandleProductChanged;
crossEventHandlerTable[CrossEventType.SHOP_OPENED] = HandleShopOpenedClosed;
crossEventHandlerTable[CrossEventType.SHOP_CLOSED] = HandleShopOpenedClosed;
crossEventHandlerTable[CrossEventType.LOGGED_OUT] = HandleLoggedOut;

export default crossEventHandlerTable;
