import { toast } from "react-toastify";
import EventSystem from "../EventSystem";
import Config from "../Config";
import ErrorMessage from "./ErrorMessages";
import type { ImageType } from "../../model/Image";
import { Image } from "../../model/Image";
import type { MessageBase } from "../ws/MessageHandler";

function uploadFile(file: File, type: ImageType, cb: (response: MessageBase & { image: Image })=>{}) {
  let formData: FormData = new FormData();
  formData.append("file", file, file.name);
  let url = Config.APIBaseURL + "/image";
  url += "&type=" + type;
  if (window.localStorage.getItem("usertoken"))
    url += "&usertoken=" + window.localStorage.getItem("usertoken");

  let xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.withCredentials = true;
  xhr.onload = () => {
    if (xhr.status === 200) {
      if (cb)
        cb(JSON.parse(xhr.responseText));
    } else {
      if (cb)
        cb({error: 1, type: 0});
    }
  };
  xhr.send(formData);
}

function ownFetch(quite, method, url, params, callback) {
  console.log(Config.APIBaseURL + url + "::" + method + " called");

  let init = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    },
    credentials: "include",
    withCredentials: true,
  };
  if (window.localStorage.getItem("usertoken") != null) {
    params.usertoken = window.localStorage.getItem("usertoken");
  }

  if (method !== "GET") {
    init.body = JSON.stringify(params);
  }

  fetch(Config.APIBaseURL + url, init)
    .then((res) => {
      return res.text();
    })
    .then((res) => {
      try {
        let result = JSON.parse(res);
        if (result.error === ErrorMessage.TOKEN_NOT_VALID) {
          EventSystem.publish(EventSystem.events.authentication_changed, false);
        }
        if (!quite) {
          if (result.error === ErrorMessage.WRONG_EMAIL_OR_PASS) {
            toast("Hibás email cím vagy jelszó.");
          } else if (result.error === ErrorMessage.PERMISSION_DENIED) {
            toast("Hozzáférés megtagadva.");
          } else if (
            result.error === ErrorMessage.PARAMETER_NOT_VALID ||
            result.error === ErrorMessage.DATA_NOT_FOUND ||
            result.error === ErrorMessage.AMBIGUOUS ||
            result.error === ErrorMessage.UNEXPECTED_ERROR ||
            result.error === ErrorMessage.BODY_NOT_PARSABLE ||
            result.error === ErrorMessage.ILLEGAL_STATE
          ) {
            toast("Ismeretlen hiba lépett fel - " + result.error + ".");
          } else if (result.error === ErrorMessage.EMAIL_NOT_VALID) {
            toast("Hibás email cím!");
          } else if (result.error === ErrorMessage.TOKEN_NOT_VALID) {
            toast("Kérem jelentkezzen be!");
          } else if (result.error === ErrorMessage.DATABASE_ERROR) {
            toast("Adatbázis hiba!");
          } else if (result.error === ErrorMessage.DATA_NOT_FOUND) {
            toast("Rekord nem található.");
          } else if (result.error === ErrorMessage.MENU_NOT_REMOVABLE) {
            toast("Termék nem törölhető, mivel még egy menü része!");
          } else if (result.error === ErrorMessage.PENDING_OR_VALID_CONTRACT_IS_AVAILABLE) {
            toast("Már van folyamatban lévő vagy hatályos szerződés!");
          } else if (result.error === ErrorMessage.PROFILE_NOT_FOUND) {
            toast("Felhasználói profil nem található.");
          } else if (result.error === ErrorMessage.SIP_IS_NOT_AVAILABLE_FOR_THIS_USER) {
            toast("Nincs telefonos azonosító beállítva!");
          }
        }
        if (callback)
          callback(res);
      } catch (e) {
        console.log(e);
      }
    })
    .catch((reason) => {
      console.log(reason);
      toast("A szerver nem elérhető.");
    });
}

// eslint-disable-next-line
export default {
  ownFetch,
  uploadFile,
};
