import Job from "../../model/Job";
import ContextSystem from "../ContextSystem";
import EventSystem from "../EventSystem";
import { MessageSender } from "./MessageSender";
import MessageType from "./MessageType";
import type { CrossEventDataBase } from "./CrossEventHandler";
import CrossEventHandler from "./CrossEventHandler";

type HandlerType = (data: any) => void;

const HandleOk: HandlerType = () => {
};

const HandleError: HandlerType = (data: { error: any }) => {
  EventSystem.publish(EventSystem.events.SHOW_TOAST, "Unexpected error - Code: #" + data.error);
  //MessageSender.sendError(Object.keys(ErrorMessage).includes(data.error) ? data.error : ErrorMessage.UNEXPECTED_ERROR);
};

const HandlePing: HandlerType = () => {
  MessageSender.sendPong();
};

export type JobOffer = { jobID: number; pc: number; firstPickupPlace?: string; distanceTotal: number; durationTotal: number; commission: number };
type JobOfferIn = { job: Job, group_id: number, packets_number: number, first_pickup_place: number, distance_total: number, duration_total: number, commission: number };

const HandleJobOffer: HandlerType = (data: JobOfferIn) => {
  if (data.job) {
    let job: Job = data.job;
    ContextSystem.job = job;
    EventSystem.publish(EventSystem.events.JOB_CHANGED, job);
  } else {
    let jobID = data.group_id;
    let pc = data.packets_number;
    let firstPickupPlace = data.first_pickup_place;
    let distanceTotal = data.distance_total;
    let durationTotal = data.duration_total;
    let commission = data.commission;
    let jobOffer: JobOffer = { jobID, pc, firstPickupPlace, distanceTotal, durationTotal, commission };
    EventSystem.publish(EventSystem.events.JOB_OFFER_RECEIVED, jobOffer);
  }
};

const HandleJobOfferTimeUp: HandlerType = (data: { jobID: number }) => {
  EventSystem.publish(EventSystem.events.JOB_OFFER_TIME_UP, data.jobID);
};

export type MessageBase = { error: number, type: number };
type CrossEventMessage = MessageBase & { data: CrossEventDataBase };

const HandleCrossEventPublish: HandlerType = (msg: CrossEventMessage) => {
  let eventType: number = msg.data.type;
  if (CrossEventHandler[eventType])
    CrossEventHandler[eventType](msg.data, eventType);
  else
    console.log("eventType handler is not found - " + eventType);
};


// noinspection JSUnusedLocalSymbols
const HandleCrossEventRegistered: HandlerType = (msg: MessageBase) => {
//todo
};

// noinspection JSUnusedLocalSymbols
const HandleCrossEventUnregistered: HandlerType = (msg: MessageBase) => {
//todo
};

let handlerTable: {
  [type: number]: HandlerType;
} = {};
handlerTable[MessageType.CROSS_EVENT_PUBLISH] = HandleCrossEventPublish;
handlerTable[MessageType.OK] = HandleOk;
handlerTable[MessageType.ERROR] = HandleError;
handlerTable[MessageType.PING] = HandlePing;
handlerTable[MessageType.JOB_OFFER] = HandleJobOffer;
handlerTable[MessageType.JOB_OFFER_TIME_UP] = HandleJobOfferTimeUp;
handlerTable[MessageType.CROSS_EVENT_REGISTERED] = HandleCrossEventRegistered;
handlerTable[MessageType.CROSS_EVENT_UNREGISTERED] = HandleCrossEventUnregistered;

export default handlerTable;
