import React, {Component} from "react";
import styled, {css} from "styled-components";
import {ImCross} from "react-icons/all";

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
  background-color: rgba(94, 94, 94, 0.28);
  z-index: ${({zindex}) => zindex};
  display: flex;
  justify-content: center;
  transform: translateY(-100%);
  transition: transform 500ms ease-in-out;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 60px 0;

  ${({show}) => show === true && css` 
    transform: translateY(0);
  `};

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 500px;
  max-width: 80%;
  pointer-events: auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  ${({size}) => size === "sm" && css`
    width: 300px;
  `};
  ${({size}) => size === "lg" && css`
    width: 800px;
  `};
  ${({size}) => size === "xl" && css`
    width: 1200px;
  `};
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
`;

const HeaderCloseButton = styled.button`
  font-weight: 700;
  color: #808080;
  background-color: transparent;
  border: 0;
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  &:hover {
    color: #000;
  }
`;

const TitleWrapper = styled.h4`
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.5rem;
  font-weight: 500;
`;

const BodyWrapper = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  & > div {
    margin: 0.25rem;
  }
`;

export default class Modal extends Component {
  static zindex = 100;
  static Header = ({children, closeButton}) => (
    <HeaderWrapper>
      {children}
      {closeButton && (
        <HeaderCloseButton onClick={closeButton}>
          <ImCross/>
        </HeaderCloseButton>
      )}
    </HeaderWrapper>
  );
  static Title = ({children}) => <TitleWrapper>{children}</TitleWrapper>;
  static Body = ({children}) => <BodyWrapper>{children}</BodyWrapper>;
  static Footer = ({children}) => <FooterWrapper>{children}</FooterWrapper>;

  state = {
    zindex: Modal.zindex,
  };

  onEscapeKeyDownHandler(e) {
    let focused = Modal.activePopUp === this;
    if (focused && e.keyCode === 27 && this.props.onEscapeKeyDown) this.props.onEscapeKeyDown();
  }

  static activePopUp;
  prevActivePopUp;

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show === false && this.props.show === true) {
      this.setState({zindex: Modal.zindex++});
      if (!Modal.activePopUp) {
        Modal.activePopUp = this;
      } else {
        this.prevActivePopUp = Modal.activePopUp;
        Modal.activePopUp = this;
      }
    } else if (prevProps.show === true && this.props.show === false) {
      if (this.prevActivePopUp) {
        Modal.activePopUp = this.prevActivePopUp;
        this.prevActivePopUp = undefined;
      } else {
        Modal.activePopUp = undefined;
      }
    }
  }

  componentDidMount() {
    this.setState({zindex: Modal.zindex++});
    document.addEventListener("keydown", (e) => this.onEscapeKeyDownHandler(e));
  }

  mouseDownOnChild;

  handleMouseDownInChild(e) {
    e.stopPropagation();
    this.mouseDownOnChild = true;
  }

  handleMouseUpInChild(e) {
    e.stopPropagation();
    this.mouseDownOnChild = false;
  }

  handleMouseUpInParent() {
    if (!this.mouseDownOnChild) {
      this.props.onEscapeKeyDown && this.props.onEscapeKeyDown()
    } else {
      this.mouseDownOnChild = false;
    }
  }

  render() {
    return (
      <Wrapper onMouseDown={e => e.preventDefault()}
               onMouseUp={() => this.handleMouseUpInParent()} show={this.props.show}
               zindex={this.state.zindex}
      >
        <InnerWrapper onMouseDown={(e) => this.handleMouseDownInChild(e)}
                      onMouseUp={(e) => this.handleMouseUpInChild(e)}
                      size={this.props.size}
        >
          {this.props.children}
        </InnerWrapper>
      </Wrapper>
    );
  }
}
