/* eslint-disable no-unused-vars */
import React, {Component} from "react";
import {NavLink, withRouter} from "react-router-dom";
import HeaderLogo from "./HeaderLogo";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import {Contract} from "../../model/Contract";

const HeaderWrapper = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  z-index: 20;

  //padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  /*choose*/
  background-color: white;
  /*background-image: linear-gradient(45deg, rgba(141, 21, 22, 0.9), rgba(18, 25, 69, 0.9), rgba(255, 68, 70, 0.9));*/
  box-shadow: 0 0 10px 1px #6a0800;
`;

const NavBar = styled.div`
  width: 100%;
  height: 60px;
  padding: 0 5px 5px 5px;
  display: flex;
  top: 0;
  left: 0;
  z-index: 19;

  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  color: #a62024;

  .logo-navlink {
    height: 100%;
    box-sizing: border-box;
  }
`;

class Header extends Component {
  lastScroll = 0;
  lastWidth = 0;
  lastHeight = 0;

  state: {
    menuOpened: boolean,
    windowWidth: number,
    windowHeight: number,
    scrolled: boolean,
    showing: boolean,
    mouseAtTop: boolean,
    authenticated: boolean,
    editSearch: string,
    contracts: Contract[],
  } = {
    menuOpened: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    scrolled: false,
    showing: true,
    mouseAtTop: true,
    authenticated: ContextSystem.loggedIn,
    editSearch: "",
    contracts: ContextSystem.contracts,
  };

  menuStateChanged(opened) {
    this.setState({menuOpened: opened});
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  eventIDs = [];

  componentDidMount() {
    this.lastHeight = window.innerHeight;
    this.lastWidth = window.innerWidth;

    EventSystem.subscribe(EventSystem.events.sideMenu, (opened) => {
      this.menuStateChanged(opened);
    });

    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        this.setState({scrolled: true});
      } else {
        this.setState({scrolled: false});
      }
      if (this.lastHeight === window.innerHeight) {
        if (this.lastScroll < window.scrollY && window.scrollY > 100) {
          this.setState({showing: false});
          EventSystem.publish(EventSystem.events.sideMenu, false);
        } else {
          this.setState({showing: true});
        }
      }
      this.lastHeight = window.innerHeight;
      this.lastWidth = window.innerWidth;
      this.lastScroll = window.scrollY;
    });

    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({loggedIn, contracts}) => {
      if (loggedIn !== undefined) {
        this.setState({authenticated: loggedIn});
      }
      if (contracts !== undefined) {
        this.setState({contracts});
      }
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <HeaderWrapper>
        <NavBar>
          <NavLink className={"logo-navlink"} to={"/"}>
            <HeaderLogo/>
          </NavLink>
        </NavBar>
      </HeaderWrapper>
    );
  }

  handleContractClick() {
    EventSystem.publish(EventSystem.events.open_contracts_details);
  }
}

export default withRouter(Header);
