import React, {Component} from "react";
import styled, {css} from "styled-components";
import {PageContainer} from "../components/PageContainer";
import {AuthAPI} from "../utils/api/AuthAPI";
import {withRouter} from "react-router-dom";
import EventSystem from "../utils/EventSystem";
// import Orders, {pulseBgAnimation} from "../components/home/Orders";
// import Products from "../components/home/Products";
// import Settings from "../components/home/Settings";
// import DetailedSettings from "../components/home/DetailedSettings";
// import ProductEditor from "../components/modals/ProductEditor";
// import Menus from "../components/home/Menus";
// import Extras from "../components/home/Extras";
import ImageUploader from "../components/modals/ImageUploader";
// import ExtrasSelector from "../components/modals/ExtrasSelector";
// import ExtraEditor from "../components/modals/ExtraEditor";
// import MenuEditor from "../components/modals/MenuEditor";
// import OrderStateCommentEditor from "../components/modals/OrderStateCommentEditor";
// import CategoriesEditor from "../components/modals/CategoriesEditor";
// import Integrations from "../components/home/Integrations";
import {AiTwotoneShop, FaCogs, FaSignOutAlt, GiTicket, HiUsers, MdTimeline} from "react-icons/all";
import ContextSystem from "../utils/ContextSystem";
// import HelpPage from "../components/home/HelpPage";
import Config from "../utils/Config";
import {Shop} from "../model/Shop";
import {OrderState} from "../model/Order";
import Settings from "../components/home/Settings";
import Shops from "../components/home/Shops";
import Tickets from "../components/home/Tickets";
import AdminProfiles from "../components/home/AdminProfiles";
import RealTime from "../components/home/RealTime";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 60px); //60 + 30

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SideMenu = styled.div`
  width: 20vw;
  max-width: 250px;
  height: 100%;

  background-color: white;
  padding: 0 10px 0 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 0 10px 1px gray;
  z-index: 2;

  & > hr {
    display: block;
    width: 90%;
    height: 1px;
    color: #841a1e;
    padding: 0;
    margin: 10px 0;
  }

  //@media screen and (max-width: 1000px) {
  //  display: none;
  //}
`;

const MenuItem = styled.div`
  width: 100%;
  min-height: 25px;
  padding: 8px 0;

  margin-top: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  //background-color: #e9e9e9;
  border-radius: 10px;

  ${({bottom}) => bottom && css`
    margin-top: auto;
    margin-bottom: 0;
  `}
  & > span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    & > i, & > svg {
      margin-left: 5px;
      width: 20%;
      font-size: 18pt;
    }
  }

  & > i, & > svg {
    margin-left: 5px;
    width: 20%;
    font-size: 18pt;
  }

  &:hover, &:active {
    background-color: #edf0f6;
    cursor: pointer;
  }

  ${({selected}) => selected === true && css`
    background-color: #edf0f6;
    cursor: pointer;
  `}
`;

const DashBoardWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: auto;
`;

class HomePage extends Component {
  state: {
    loggedIn: boolean,
    profile: Shop,
    selectedMenu: number,
    mobile: boolean,
    hiddenProduct: number,
    hiddenMenu: number,
    newOrdersNumber: number,
    menuOpened: boolean,
  } = {
    loggedIn: ContextSystem.loggedIn,
    profile: ContextSystem.profile,
    selectedMenu: 0,
    mobile: window.innerWidth < 1000,
    hiddenProduct: 0,
    hiddenMenu: 0,
    newOrdersNumber: 0,
    menuOpened: true
  };

  eventIDs = [];

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid)
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    window.addEventListener("resize", () => {
      this.setState({mobile: window.innerWidth < Config.mobileMaxWidth});
    });

    this.eventIDs = [];

    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged,
      ({products, menus, loggedIn, profile, newOrders}) => {
        if (products !== undefined) {
          let newHiddenProduct = products.filter((p) => !p.active).length;
          if (this.state.hiddenProduct !== newHiddenProduct) {
            this.setState({hiddenProduct: newHiddenProduct});
          }
        }

        if (menus !== undefined) {
          let newHiddenMenu = menus.filter((x) => !x.active).length;
          if (this.state.hiddenMenu !== newHiddenMenu) {
            this.setState({hiddenMenu: newHiddenMenu});
          }
        }

        if (loggedIn !== undefined) {
          this.setState({profile, loggedIn})
          if (loggedIn === false) {
            this.props.history.push("/bejelentkezes");
          }
        }

        if (newOrders !== undefined) {
          let newOrdersNumber = 0;
          newOrders.forEach((order) => newOrdersNumber += order.lastState.status === OrderState.NEW ? 1 : 0)
          this.setState({newOrdersNumber})
        }
      });
    this.eventIDs.push(eid);
  }


  selectMenu(menu) {
    this.setState({selectedMenu: menu});
  }

  render() {
    return (
      <PageContainer>
        {this.state.loggedIn && (
          <Wrapper>
            {/*<AddContractModal/>*/}
            {/*<ContractsDetailsModal/>*/}
            {/*<ShowContractModal/>*/}
            {/*<CategoriesEditor/>*/}
            {/*<ExtraEditor/>*/}
            {/*<ProductEditor/>*/}
            <ImageUploader/>
            {/*<ExtrasSelector/>*/}
            {/*<MenuEditor/>*/}
            {/*<OrderStateCommentEditor/>*/}

            {/*{this.state.mobile === false && (*/}
            {/*  <>*/}
            <SideMenu menuOpened={this.state.menuOpened}>
              <MenuItem selected={this.state.selectedMenu === 0} onClick={() => this.selectMenu(0)}>
                <MdTimeline/>
                Real-time
              </MenuItem>
              <MenuItem selected={this.state.selectedMenu === 1} onClick={() => this.selectMenu(1)}>
                <AiTwotoneShop/>
                Éttermek
              </MenuItem>
              <MenuItem selected={this.state.selectedMenu === 2} onClick={() => this.selectMenu(2)}>
                <HiUsers/>
                Felhasználók
              </MenuItem>
              <MenuItem selected={this.state.selectedMenu === 3} onClick={() => this.selectMenu(3)}>
                <GiTicket/>
                Hibajegyek
              </MenuItem>
              <hr/>
              <MenuItem selected={this.state.selectedMenu === 4} onClick={() => this.selectMenu(4)}>
                <FaCogs/>
                Beállítások
              </MenuItem>
              <MenuItem onClick={() => AuthAPI.logout()} bottom>
                <FaSignOutAlt/>
                Kijelentkezés
              </MenuItem>
            </SideMenu>
            <DashBoardWrapper>
              {this.state.selectedMenu === 0 && <RealTime/>}
              {this.state.selectedMenu === 1 && <Shops/>}
              {this.state.selectedMenu === 2 && <AdminProfiles/>}
              {this.state.selectedMenu === 3 && <Tickets/>}
              {this.state.selectedMenu === 4 && <Settings/>}
            </DashBoardWrapper>
          </Wrapper>
        )}
      </PageContainer>
    );
  }
}

export default withRouter(HomePage);
