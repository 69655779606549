import EventSystem from "./EventSystem";
import Config from "./Config";
import { AuthAPI } from "./api/AuthAPI";
import { Contract } from "../model/Contract";
import { CommissionRecord } from "../model/CommissionRecord";
import { GlobalCategory } from "../model/Category";
import { Product } from "../model/Product";
import { City, ZipCode } from "../model/Address";
import CategoriesAPI from "./api/CategoriesAPI";
import ZadarmaAPI from "./api/ZadarmaAPI";
import { AdminProfile } from "../model/AdminProfile";
import { initializeZadarmaWebRTCWidget } from "../Initializer";
import ContractsAPI from "./api/ContractsAPI";
import MessageType from "./ws/MessageType";
import WSConnection from "./ws/WSConnection";
import { Shop } from "../model/Shop";

export default class ContextSystem {
  static websocket: WSConnection = null;

  static data;

  static globalCategories: GlobalCategory[] = [];
  static cities: City[] = [];
  static zipcodes: ZipCode[] = [];
  static mobileDevice: boolean = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  static mobileLayout: boolean = window.innerWidth <= Config.mobileMaxWidth;
  static profile: AdminProfile = undefined;
  static loggedIn: boolean = false;
  static products: Product[] = [];
  static contracts: Contract[] = [];
  static shops: Shop[] = [];
  static commissionRecords: CommissionRecord[] = [];
  static zadarmaInitialized: boolean = false;

  // Unique ID for anything needed
  // static uniqueID = 0;
  //
  // static getNextUniqueID() {
  //     this.uniqueID++;
  //     return this.uniqueID;
  // }

  static init() {
    this.loadFromLocalStorage();
    console.log("Init ContextSystem");
    this.data = {
      globalCategories: this.globalCategories,
      cities: this.cities,
      zipcodes: this.zipcodes,
      mobileDevice: this.mobileDevice,
      mobileLayout: this.mobileLayout,
      profile: this.profile,
      loggedIn: this.loggedIn,
      products: this.products,
      contracts: this.contracts,
      shops: this.shops,
      commissionRecords: this.commissionRecords,
      zadarmaInitialized: this.zadarmaInitialized
    };
    this.subscribe();
    this.reload();
  }

  static loadFromLocalStorage() {
    //Nothing to load from localstorage
  }

  static mergeCities(cities: City[], zipcodes: ZipCode[]) {
    if (!cities || !zipcodes) {
      console.error("cities or zipcodes parameter is undefined");
      return;
    }

    if (cities.length <= 0 || zipcodes.length <= 0) return;

    if (!this.cities)
      this.cities = [];
    if (!this.zipcodes)
      this.zipcodes = [];

    let changed = false;
    A: for (let city of cities) {
      for (let c of this.cities) {
        if (c.id === city.id) continue A;
      }
      changed = true;
      this.cities.push(city);
    }
    A: for (let zipcode of zipcodes) {
      for (let z of this.zipcodes) {
        if (z.id === zipcode.id) continue A;
      }
      changed = true;
      this.zipcodes.push(zipcode);
    }

    if (changed) {
      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        cities,
        zipcodes
      });
    }
  }

  static lastLoggedInValue: boolean | null = null;
  static lastProfile: AdminProfile | null = null;

  static reload() {
    if (this.lastLoggedInValue !== this.loggedIn) {
      this.lastLoggedInValue = this.loggedIn;
      this.loadProfile();
      this.loadGlobalCategories();
      this.loadContracts();
      this.startWebSocket();
      if (!this.loggedIn) {
        this.newProducts = [];
        this.newOrders = [];
        this.endWebSocket();
        EventSystem.publish(EventSystem.events.contextSystemChanged, {
          newProducts: this.newProducts,
          newOrders: this.newOrders
        });
      }
    }

    if (this.lastProfile !== this.profile) {
      if (this.profile == null)
        this.removeZadarma();
      else
        this.initializeZadarma();

      this.lastProfile = this.profile;
    }
  }

  static removeZadarma() {
    if (!this.zadarmaInitialized)
      return;

    let z: HTMLDivElement = document.getElementById("zdrmWPhI");
    if (z)
      z.remove();

    this.setZadarmaInitialized(false);
  }

  static initializeZadarma() {
    if (this.zadarmaInitialized)
      return;

    this.setZadarmaInitialized();
    ZadarmaAPI.getKey(false, res => {
      if (res.error !== MessageType.OK)
        return;

      let key: string = res.key;
      let sip: string = ContextSystem.profile.zadarmaSIP;
      initializeZadarmaWebRTCWidget(key, sip);
    });
  }

  static setZadarmaInitialized(init: boolean = true) {
    ContextSystem.zadarmaInitialized = init;
    EventSystem.publish(EventSystem.events.contextSystemChanged, { zadarmaInitialized: ContextSystem.zadarmaInitialized });
  }

  static loadProfile() {
    AuthAPI.checkLogin(true, (loggedIn: boolean, profile: AdminProfile) => {
      this.loggedIn = loggedIn;
      this.profile = profile;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        loggedIn,
        profile
      });
    });
  }

  static interval;

  static subscribe() {
    window.addEventListener("resize", () => {
      this.setMobileLayout();
    });
    EventSystem.subscribe(EventSystem.events.contextSystemChanged, (data) => {
      let changed = false;
      for (let key in data) {
        // noinspection JSUnfilteredForInLoop
        if (this.data[key] !== data[key]) {
          changed = true;
        }
        // noinspection JSUnfilteredForInLoop
        this.data[key] = data[key];
      }
      if (changed)
        this.reload();
    });

    // noinspection JSUnusedLocalSymbols
    EventSystem.subscribe(EventSystem.events.authentication_changed, ({ loggedIn, profile }) => {
      if (loggedIn === true) {
        //this.getNewOrders();
        if (this.interval)
          clearInterval(this.interval);
        //TODO websocket sync orders
        //this.interval = setInterval(() => this.getNewOrders(), 25000);
      } else {
        clearInterval(this.interval);
      }
      // this.profile = profile;
      this.loggedIn = loggedIn;
      // EventSystem.publish(EventSystem.events.contextSystemChanged, {profile, loggedIn});

      this.reload();
    });

    EventSystem.subscribe(EventSystem.events.urlChanged, () => {
      // this.reload();
    });
  }

  static setMobileLayout() {
    let newValue = window.innerWidth <= Config.mobileMaxWidth;
    if (newValue !== this.mobileLayout) {
      this.mobileLayout = newValue;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        mobileLayout: newValue
      });
    }
  }

  static loadGlobalCategories() {
    if (this.globalCategories !== undefined && this.globalCategories.length > 0)
      return;

    CategoriesAPI.getAll(false, (res) => {
      if (res.error !== MessageType.OK)
        return;

      this.globalCategories = res.globalCategories;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        globalCategories: res.globalCategories
      });
    });
  }

  static getContract(partnerID: number): Contract[] {
    let f: Contract[] = [];
    for (let contract of this.contracts) {
      if (contract.partnerID === partnerID)
        f.push(contract);
    }
    return f;
  }

  static loadContracts() {
    if (this.contracts !== undefined && this.contracts.length > 0)
      return;

    ContractsAPI.getAll(false, (res) => {
      if (res.error !== MessageType.OK)
        return;

      this.contracts = res.contracts;
      EventSystem.publish(EventSystem.events.contextSystemChanged, {
        contracts: res.contracts
      });
    });
  }

  static getZipCode(zipCodeID: number) {
    for (let zipcode of this.zipcodes) {
      if (zipcode.id === zipCodeID)
        return zipcode;
    }
    return null;
  }

  static getCityByID(id: number) {
    for (let city of this.cities) {
      if (city.id === id)
        return city;
    }
    return null;
  }

  static startWebSocket() {
    this.websocket = WSConnection.getInstance();
    this.websocket.open(() => {
      console.log("WebSocket connection opened!");
    });
  }

  static endWebSocket() {
    if (!this.websocket)
      return;
    this.websocket.close();
  }
}
