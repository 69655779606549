import styled from 'styled-components';

export const Button = styled.button`
  display: ${({inline}) => inline ? "inline-block" : "block"};
  background-color: white;
  border: 1px solid #3b6499;
  border-radius: 5px;
  padding: 8px 20px;
  outline: none;
  margin: 0 auto;

  font-size: 12pt;
  font-family: "Raleway Black", sans-serif;
  text-align: center;
  transition: height 0.2s ease-in-out, background-color 200ms ease-in-out, color 200ms ease-in-out;
  color: #3b6499;

  &:hover, &:active {
    outline: none;
    background-color: #4a7dbf;
    cursor: pointer;
    color: white;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12pt;
  }
`;

export const Input = styled.input`
  width: ${({width}) => (width ? width : "70%")};
  padding: 5px 10px;
  margin: 10px 0;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({width}) => (width ? width : "100%")};
  }
`;
