import APICaller from "./APICaller";
import EmailValidator from "../EmailValidator";
import {toast} from "react-toastify";
import EventSystem from "../EventSystem";
import MessageType from "../ws/MessageType";
import {AdminProfile} from "../../model/AdminProfile";

let authenticated: boolean | null = null;
let profile: AdminProfile | null = null;
let checkingAuthentication: boolean = false;

function requestPasswordReset(email: string, cb: (res: { error: number, type: number })=>{}) {
  APICaller.ownFetch(false, "PUT", "/password", {email}, (res) => {
    let result = JSON.parse(res);
    if (result.error === MessageType.OK) {
      //The server signs this user off, that's why we need to do it here as well.
      authenticated = false;
      EventSystem.publish(EventSystem.events.authentication_changed, {loggedIn: false, profile: null});
    }
    if (cb)
      cb(result);
  });
}

function login(email: string, password: string, cb: (authenticated: boolean)=>{}) {
  if (!EmailValidator(email)) {
    return false;
  }
  APICaller.ownFetch(false, "POST", "/login", {email, password}, data => defaultLoginHandler(data, cb));
}

function defaultLoginHandler(data: string, cb: (authenticated: boolean)=>{}) {
  let result = JSON.parse(data);
  if (result.error !== MessageType.OK) {
    toast("Hiba. Kérem próbáld újra később.");
  } else {
    if (result.authenticated) {
      window.localStorage.setItem("usertoken", result.token);
      authenticated = true;
      profile = result.profile;
      EventSystem.publish(EventSystem.events.authentication_changed, {loggedIn: true, profile: profile});
      if (cb)
        cb(true);
      return;
    } else {
      toast("Email cím vagy jelszó hibás.");
    }
  }
  authenticated = false;
  EventSystem.publish(EventSystem.events.authentication_changed, false);
  if (cb)
    cb(false);
}

function checkLogin(quite: boolean, cb: (loggedIn: boolean, profile: AdminProfile)=>{}) {
  if (authenticated != null) {
    if (!authenticated) {
      if (cb)
        cb(authenticated, null);
      return;
    } else if (authenticated && profile != null) {
      if (cb)
        cb(authenticated, profile);
      return;
    }
  }

  if (checkingAuthentication) {
    setTimeout(() => checkLogin(quite, cb), 100);
    return;
  }

  checkingAuthentication = true;

  APICaller.ownFetch(quite, "POST", "/login", {}, (res) => {
    let oldA = authenticated;
    let result = JSON.parse(res);

    if (result.error === 0) {
      profile = result.profile;
      authenticated = true;
    } else {
      profile = null;
      authenticated = false;
    }
    if (cb)
      cb(authenticated, profile);
    checkingAuthentication = false;

    if (oldA !== authenticated)
      EventSystem.publish(EventSystem.events.authentication_changed, {loggedIn: authenticated, profile});
  });
}

function logout() {
  APICaller.ownFetch(true, "DELETE", "/login", {});
  authenticated = false;
  profile = null;
  EventSystem.publish(EventSystem.events.authentication_changed, {loggedIn: false, profile: null});
}

function changePassword(oldPass: string, newPass: string, cb: (res: { error: number, type: number })=>{}) {
  APICaller.ownFetch(
    false,
    "POST",
    "/password",
    {
      old: oldPass,
      new: newPass,
    },
    (data) => {
      let res = JSON.parse(data);
      if (cb)
        cb(res);
    }
  );
}

export const AuthAPI = {
  login,
  checkLogin,
  logout,
  requestPasswordReset,
  changePassword,
  defaultLoginHandler,
};
