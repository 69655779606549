import APICaller from "./APICaller";
import {GlobalCategory} from "../../model/Category";

function getAll(quite: boolean, cb: (res: { error: number, type: number, globalCategories: GlobalCategory[] | null })=>{}) {
  APICaller.ownFetch(quite, "POST", "/categories", {}, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export default {
  getAll,
};
