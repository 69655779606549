import React, {Component} from 'react';
import notification_sound from "../assets/notification.mp3";
import EventSystem from "../utils/EventSystem";
import {Order, OrderState} from "../model/Order";
import ConfirmationModal from "./modals/ConfirmationModal";
import ContextSystem from "../utils/ContextSystem";
import logo from "../assets/logo_teljes_kor.png"

export default class NotificationSound extends Component {
  lastPermission: NotificationPermission;
  loggedIn: boolean = false;
  sentNotification: string[] = [];

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.authentication_changed, () => {
      this.setupPermission();
      this.stopSound();
    })

    EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({newOrders}) => {
      if (newOrders !== undefined) {
        for (let newOrder of newOrders) {
          if (newOrder.lastState.status === OrderState.NEW) {
            this.notifyNewOrders(newOrders);
            break;
          }
        }
      }
    });
  }

  setupPermission() {
    this.lastPermission = Notification.permission;

    this.loggedIn = ContextSystem.loggedIn;

    if (!this.loggedIn)
      return;

    let callback = () => {
      Notification.requestPermission().then((result: NotificationPermission) => {
        this.lastPermission = Notification.permission;
        if (result !== "granted") {
          let msg2 = (
            <p>
              Nem adtad meg az engedélyt, hogy értesítést tudjunk küldeni számodra.<br/>
              Ha szeretnél rendszerszintű értesítést kapni a bejövő rendelésekről, kérlek engedélyezd a böngésző
              beállításai között.
            </p>
          );
          ConfirmationModal.showModal("Böngésző beállítás", msg2, "Rendben");
        }
      })
    };

    if (Notification.permission === "default") {
      let msg = (
        <p>
          Szeretnénk rendszerértesítőt küldeni, amikor új rendelés érkezik.<br/>
          Kérlek engedélyezd az értesítés küldését, hogy minél gyorsabban tudjuk kiszolgálni a vendégeket!<br/>
          <br/>
          Köszönöm!
        </p>
      );
      ConfirmationModal.showModal("Bejövő rendelések", msg, "Rendben", "", callback, callback);
    }
  }

  notifyNewOrders(orders: Order[]) {
    this.playNotificationSound();
    let newIDs: string[] = [];

    orders.forEach(o => {
      if (o.lastState.status === OrderState.NEW) {
        let a = this.sentNotification.find(v => v === o.number);
        if (a !== o.number)
          newIDs.push(o.number);
      }
    });
    if (newIDs.length <= 0)
      return;

    let title: string = "EnniAkarok rendelés érkezett!";
    let message: string = newIDs.length + " darab rendelésed érkezett! Igazold vissza az új rendelés" + (newIDs.length > 1 ? "eke" : "") + "t!";
    new Notification(title, {body: message, icon: logo});

    newIDs.forEach(id => this.sentNotification.push(id));
  }

  playNotificationSound() {
    const audioEl: HTMLAudioElement = document.getElementsByClassName("audio-element")[0];
    if (audioEl) {
      audioEl.currentTime = 0;
      audioEl.play();
    }
  }

  stopSound() {
    const audioEl: HTMLAudioElement = document.getElementsByClassName("audio-element")[0];
    if (audioEl) {
      audioEl.currentTime = 0;
    }
  }

  render() {
    return (
      <audio className="audio-element">
        <source src={notification_sound}/>
      </audio>
    );
  }
}
