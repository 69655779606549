import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
// import "babel-polyfill";
import fill from "./utils/DatePolyfill";
import {polyfill} from "es6-promise";
import {toast} from "react-toastify";
import ContextSystem from "./utils/ContextSystem";

let initialized = false;

function initialize() {
  if(initialized)
    return;

  fill();
  //polyfill-ing other languages (e.g.: es6 can work on ie as well)
  polyfill();
  toast.configure();
  initialized = true;
  reloadAtMidnight();
  ContextSystem.init();
}

// eslint-disable-next-line
Array.prototype.remove = function () {
  let what,
    a = arguments,
    L = a.length,
    ax;
  while (L && this.length) {
    what = a[--L];
    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }
  return this;
};

function reloadAtMidnight() {
  let now = new Date();
  let midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 5, 0, 0);

  let milliseconds = midnight - now;

  if (milliseconds < 0) {
    milliseconds += 86400000; // it's after 10am, try 10am tomorrow.
  }
  setTimeout(function () {
    window.location.reload(true);
    reloadAtMidnight();
  }, milliseconds);
}

export function initializeZadarmaWebRTCWidget(key: string, sip: string) {
  window.zadarmaWidgetFn(
    key,
    sip,
    'rounded' /*square|rounded*/,
    'en' /*ru, en, es, fr, de, pl, ua*/,
    true,
    "{right:'10px',bottom:'5px'}"
  );
  configureZadarmaWebRTCWidget();
}

function configureZadarmaWebRTCWidget() {
  let e: HTMLDivElement = document.getElementsByClassName("zdrm-webphone-placeholder-double")[0];
  let input: HTMLInputElement = document.getElementById("zdrm-webphone-phonenumber-input");
  if (!e || !e.innerHTML || !input) {
    setTimeout(() => configureZadarmaWebRTCWidget(), 200);
    return;
  }
  e.innerHTML = "Telefonszám hívása";
  input.placeholder = "Telefonszám hívása"
}

initialize();
