import React, { Component } from "react";
// import styled from "styled-components";

// const Wrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-around;
//   align-self: center;
//
//   width: 100%;
//   height: 30px;
//
//   background-color: #931c20;
//   z-index: 3;
//   @media screen and (max-width: 600px) {
//     height: fit-content;
//   }
// `;

// const Copyright = styled.div`
//   color: white;
//   text-align: center;
//   margin: 0;
//   padding: 0;
//
//   & > span > a {
//     text-decoration: underline;
//     margin-left: 5px;
//     color: white;
//   }
// `;

export default class Footer extends Component {
  // static getYear() {
  //   return new Date().getFullYear();
  // }

  render() {
    return (
      <></>
    );
    // return (
    //   <Wrapper>
    //     <Copyright>
    //       <span>
    //         {" © 2020"}
    //         {Footer.getYear() > 2020 ? "-" + Footer.getYear() : ""} EnniAkarok.hu - XYZ OpThink Group Kft. - Admin Panel
    //       </span>
    //     </Copyright>
    //   </Wrapper>
    // );
  }
}
