import "./Initializer";
import React, {Component} from "react";

import "react-toastify/dist/ReactToastify.css";
import 'rc-steps/assets/index.css'

import {BrowserRouter, Route, Switch} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import {Helmet} from "react-helmet/es/Helmet";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import Header from "./components/header/Header";
import ScrollToTop from "./utils/ScrollToTop";
import Footer from "./components/Footer";
import ConfirmationModal from "./components/modals/ConfirmationModal";
import NotificationSound from "./components/NotificationSound";

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <ScrollToTop>
            <Header/>
            <Helmet>
              <title>EnniAkarok Admin</title>
              <meta name="description" content="Admin portál"/>
            </Helmet>
            <ToastContainer
              autoClose={3000}
              draggablePercent={40}
              position={toast.POSITION.TOP_RIGHT}
              progressStyle={{
                height: "5px",
                background: "#f33242",
              }}
            />
            <ConfirmationModal/>
            <NotificationSound/>
            <Switch>
              <Route path={"/bejelentkezes"} component={() => <LoginPage redirect={true}/>} exact/>
              <Route path={"/"} component={HomePage} exact/>
              <Route component={ErrorPage}/>
            </Switch>
            <Footer/>
          </ScrollToTop>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
