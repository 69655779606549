import React, {Component} from "react";
import APICaller from "../../utils/api/APICaller";
import EventSystem from "../../utils/EventSystem";
import Modal from "./Modal";
import {Button} from "../form/Form";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import {toast} from "react-toastify";

const ImageCropper = styled(ReactCrop)`
  width: fit-content;
`;

const Body = styled(Modal.Body)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled.label`
  width: 100%;
  text-align: left;
`;

export default class ImageUploader extends Component {
  state = {
    showModal: false,
    title: "Kép feltöltése",
    editFileName: "Termékkép",
    crop: {
      unit: "%",
      width: 100,
      aspect: 16 / 9,
    },
    fileSrc: undefined,
    croppedImageFile: undefined,
  };

  save() {
    if (!this.state.fileSrc || !this.state.croppedImageFile) {
      toast("Kérlek tölts fel egy képet.");
      return;
    }

    APICaller.uploadFile(this.state.croppedImageFile, (res) => {
      EventSystem.publish(EventSystem.events.image_uploaded, res);
      this.setState({showModal: false});
    });
  }

  componentDidMount() {
    EventSystem.subscribe(EventSystem.events.upload_image, () => {
      this.setState({
        showModal: true,
        editFileName: "Termékkép",
        crop: {
          unit: "%",
          width: 100,
          aspect: 16 / 9,
        },
        fileSrc: undefined,
        croppedImageFile: undefined,
      });
    });
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    // noinspection JSIgnoredPromiseFromCall
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({crop});
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageFile = await this.getCroppedImg(this.imageRef, crop, this.state.editFileName);
      this.setState({croppedImageFile});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(blob);
      }, "image/jpeg");
    });
  }

  render() {
    return (
      <Modal show={this.state.showModal} size={"lg"} onEscapeKeyDown={() => this.setState({showModal: false})}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Body>
          <Label>Válassz ki egy fájlt</Label>
          <input
            type={"file"}
            style={{marginTop: "5px", marginBottom: "5px"}}
            id="custom-file2"
            accept={"image/*"}
            label={this.state.editFileName ? this.state.editFileName : ""}
            custom
            data-browse={"Fájl kiválasztás"}
            onChange={(e) => {
              if (e.target.files.length > 0) {
                //this.uploadFile(e.target.files[0], 2);
                const reader = new FileReader();
                reader.addEventListener("load", () => this.setState({fileSrc: reader.result}));
                reader.readAsDataURL(e.target.files[0]);
              }
              this.setState({
                editFileName: e.target.files.length > 0 ? e.target.files[0].name : "Termékkép",
              });
            }}
          />
          <Label>Kép kivágása (16/9 arány)</Label>
          <ImageCropper
            src={this.state.fileSrc}
            crop={this.state.crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        </Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.setState({showModal: false})}>
            Mégsem
          </Button>
          <Button variant="primary" onClick={() => this.save()}>
            Feltöltés
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
