import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: fit-content;
  min-height: calc(100vh - 60px/* - 30px*/); //header + footer

  margin-top: ${({ margintop }) => (margintop ? margintop : "60px")}; //header
`;
