export default class CrossEventType {
  static ORDER_CHANGED: number = 0;
  static PRODUCT_CHANGED: number = 1;
  static MESSAGE_RECEIVED: number = 2;
  static JOB_ACCEPTED: number = 3;
  static CATEGORY_CHANGED: number = 4;
  static SHOP_CLOSED: number = 5;
  static SHOP_OPENED: number = 6;
  static LOGGED_OUT: number = 7;
  static EXTRA_CHANGED: number = 8;
}
