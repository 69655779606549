import APICaller from "./APICaller";
import {Contract} from "../../model/Contract";

function getAll(quite: boolean, cb: (res: { error: number, type: number, contracts: Contract[] | null })=>{}) {
  APICaller.ownFetch(quite, "POST", "/contracts", {}, data => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

// eslint-disable-next-line
export default {
  getAll,
};
