import React, {Component, ReactElement} from "react";
import EventSystem from "../../utils/EventSystem";
import styled from "styled-components";
import {Button as Button_Org} from "../../pages/LoginPage";
import Modal from "./Modal";

const Details = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const Button = styled(Button_Org)`
  background-color: #e30813;
  margin: 5px 10px;
  color: white;

  @media screen and (max-width: 800px) {
    margin: 5px 10px;
  }
`;

export default class ConfirmationModal extends Component {
  static showModal(title: string, text: string | ReactElement, yesText: string, noText: string, cancelCb: ()=>{}, proceedCb: ()=>{}) {
    EventSystem.publish(EventSystem.events.show_confirmation_modal, {
      title,
      text,
      yesText,
      noText,
      cancelCb,
      proceedCb
    });
  }

  state = {
    showModal: false,
    title: "",
    text: "",
    cancelCb: undefined,
    proceedCb: undefined,
  };

  cancel() {
    this.setState({showModal: false});
    if (this.state.cancelCb) this.state.cancelCb();
  }

  proceed() {
    this.setState({showModal: false});
    if (this.state.proceedCb) this.state.proceedCb();
  }

  componentDidMount() {
    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        this.cancel();
      }
    });
    EventSystem.subscribe(EventSystem.events.show_confirmation_modal,
      ({title, text, yesText, noText, cancelCb, proceedCb}) => {
        this.setState({
          showModal: true,
          title,
          text,
          yesText,
          noText,
          cancelCb,
          proceedCb,
        });
      });
  }

  render() {
    return (
      <Modal show={this.state.showModal} onEscapeKeyDown={() => this.cancel()}>
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Details>
            {this.state.text}
          </Details>
        </Modal.Body>
        <Modal.Footer>
          {this.state.noText && this.state.noText.length > 0 &&
          <Button variant="secondary" onClick={() => this.cancel()}>
            {this.state.noText}
          </Button>
          }
          {this.state.yesText && this.state.yesText.length > 0 &&
          <Button variant="primary" onClick={() => this.proceed()}>
            {this.state.yesText}
          </Button>
          }
        </Modal.Footer>
      </Modal>
    );
  }
}
