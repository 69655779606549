import React, {Component} from "react";
import styled, {css} from "styled-components";
import {toast} from "react-toastify";
import {AuthAPI} from "../../utils/api/AuthAPI";
import EventSystem from "../../utils/EventSystem";
import ContextSystem from "../../utils/ContextSystem";
import MessageType from "../../utils/ws/MessageType";

export const Section = styled.div`
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ebebeb;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h2 {
    font-size: 16pt;
    font-family: Arial, sans-serif;
  }

  & > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > h2 {
      font-size: 16pt;
      font-family: Arial, sans-serif;
    }
  }
`;

const SubSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${({justify}) => justify ? justify : "space-between"};
  flex-wrap: wrap;

  newline {
    width: 100%;
    height: 1px;
    background-color: transparent;
  }

  & > h2 {
    ${({justify}) => justify && css`
      width: 15%;
      min-width: 230px;
    `};
    font-size: 13pt;
    font-family: Arial, sans-serif;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5%;
`;

const Input = styled.input`
  width: ${({width}) => (width ? width : "70%")};
  padding: 5px 20px;
  margin: 10px 0;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({width}) => (width ? width : "100%")};
  }

  &:focus {
    border-color: rgba(147, 28, 32, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(147, 28, 32, 0.25);
  }
`;

const LinkButton = styled.div`
  color: #007bff;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: #005ac2;
    text-decoration: underline;
  }
`;

export const Button = styled.div`
  border: 1px solid #931c20;
  border-radius: 5px;
  padding: 5px 15px;

  font-size: 12pt;
  font-family: "Raleway Black", sans-serif;
  text-align: center;
  transition: height 0.2s ease-in-out;
  color: #a62024;

  &:hover,
  &:active {
    background-color: #a62024;
    cursor: pointer;
    color: white;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12pt;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default class Settings extends Component {
  state: {
    editOldPass: string,
    editNewPass: string,
    profile: any,
  } = {
    editOldPass: "",
    editNewPass: "",
    profile: ContextSystem.profile,
  };

  savePass() {
    if (this.state.editOldPass.length < 5 || this.state.editNewPass.length < 5) {
      this.setState({editOldPass: "", editNewPass: ""});
      toast("A megadott jelszavak túl rövidek.");
      return;
    }
    AuthAPI.changePassword(this.state.editOldPass, this.state.editNewPass, (res) => {
      this.setState({editOldPass: "", editNewPass: ""});
      if (res.error === MessageType.OK)
        toast("Mentve");
    });
  }

  eventIDs = [];

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid)
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({loggedIn, profile}) => {
      if (loggedIn !== undefined) {
        this.setState({profile})
      }
    });
    this.eventIDs.push(eid);

    this.setState({
      contracts: ContextSystem.contracts,
      profile: ContextSystem.profile,
    })
  }

  render() {
    return (
      <Wrapper>
        <Section>
          <div>
            <h2>Jelszó megváltoztatása</h2>
          </div>
          <SubSection>
            <Input
              value={this.state.editOldPass}
              type="password"
              autoComplete={"old-password"}
              placeholder="Jelenlegi jelszó"
              onChange={(e) => this.setState({editOldPass: e.target.value})}
              width={"49%"}
            />
            <Input
              value={this.state.editNewPass}
              type="password"
              autoComplete={"new-password"}
              placeholder="Új jelszó"
              onChange={(e) => this.setState({editNewPass: e.target.value})}
              width={"49%"}
            />
          </SubSection>
          <ButtonWrapper>
            <Button onClick={() => this.savePass()}>Mentés</Button>
          </ButtonWrapper>
        </Section>
      </Wrapper>
    );
  }
}
