import React, { Component } from "react";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";
import { Shop } from "../../model/Shop";
import PartnersAPI from "../../utils/api/PartnersAPI";
import { Button, Input } from "../form/Form";
import { AiFillPhone, AiOutlineLogin, FaExclamation, FiPhoneCall } from "react-icons/all";
import ZadarmaCall from "../../utils/ZadarmaCall";
import { parsePhoneNumberFromString, PhoneNumber } from "libphonenumber-js";
import Config from "../../utils/Config";
import { Contract, ContractType } from "../../model/Contract";
import ContextSystem from "../../utils/ContextSystem";
import MessageType from "../../utils/ws/MessageType";
import { City, ZipCode } from "../../model/Address";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 2%;
  flex-wrap: nowrap;
`;

const HeadBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 8px 0;
  flex-shrink: 0;
  flex-grow: 0;
  position: sticky;
  top: 0;
  background-color: white;
`;

const PartnersWrapper = styled.div`
  width: 100%;
  min-height: 30px;
  display: block;
  overflow-y: auto;
  flex-grow: 2;
`;

const Partner = styled.div`
  display: flex;
  width: 100%;
  margin: 4px 0;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 5px;
  flex-wrap: wrap;
  font-size: 10pt;

  & > div {
    margin: 0 4px;
  }
`;

const PartnerStatuses = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
`;

const PartnerDeactivated = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: available;

  & > svg {
    font-size: 16px;
    color: red;
    font-weight: bold;
  }

  & > p {
    color: red;
    font-size: 12px;
    margin: 3px 0 0 0;
    padding: 0;
  }
`;

const PartnerStatusElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1px;

  & > div {
    width: 32px;
    height: 32px;
    background-color: ${({ color }) => color ?? "white"};
  }

  & > p {
    color: #242424;
    font-size: 11px;
    margin: 3px 0 0 0;
    padding: 0;
  }
`;

const PartnerActions = styled.div`
  //width: 30%;
  margin-left: auto !important;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const PartnerActionButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: 1px solid #bbbbbb;
  background-color: white;
  color: #d01313;

  margin: 0 4px;

  &:hover {
    border: 1px solid #bbbbbb;
    background-color: #d5d5d5;
    color: #848484;
  }

  &:focus {
    border: 1px solid #b8b8b8;
    background-color: #e8e8e8;
    color: #636363;
  }
`;

const PartnerID = styled.div`
  width: 5%;
  min-width: 30px;
`;

const PartnerName = styled.div`
  width: 15%;
`;

const PartnerTel = styled.div`
  width: 15%;
`;

const PartnerContact = styled.div`
  width: 15%;
`;

export default class Shops extends Component {
  state: {
    partners: Shop[],
    editSearch: string,
  } = {
    partners: [],
    editSearch: ""
  };

  eventIDs = [];

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid);
    }
  }

  componentDidMount() {
    this.eventIDs = [];

    PartnersAPI.getAll(false, (res) => {
      if (res.error === MessageType.OK) {
        this.setState({
          partners: res.shops
        });
      }
    });
  }

  render() {
    return (
      <Wrapper>
        <HeadBar>
          <Input
            value={this.state.editSearch}
            type="text"
            autoComplete={"off"}
            placeholder="Keresés..."
            onChange={(e) => this.setState({ editSearch: e.target.value })}
            width={"200px"}
          />
          {/*<Button onClick={() => this.addPartnerProfile()}>*/}
          {/*  Új létrehozása*/}
          {/*</Button>*/}
        </HeadBar>
        <PartnersWrapper>
          {this.state.partners?.map((p, i) => {
            if (!this.searchMatch(p, this.state.editSearch))
              return <React.Fragment key={i} />;

            let contractsOfPartner: Contract[] = ContextSystem.getContract(p.id);

            return (
              <Partner key={i}>
                <PartnerID>#{p.id}</PartnerID>
                <PartnerName>{p.name}</PartnerName>
                <PartnerTel><a href={"tel:" + p.publicTel}><AiFillPhone /> {p.publicTel}</a></PartnerTel>
                <PartnerContact>{p.primaryContact?.firstName} {p.primaryContact?.lastName}</PartnerContact>
                <PartnerStatuses>
                  <PartnerStatusElement color={p.registered ? "green" : "orange"}>
                    <div />
                    <p>Reg</p>
                  </PartnerStatusElement>
                  <PartnerStatusElement color={p.registered && !p.activateEmailPassphrase ? "green" : "orange"}>
                    <div />
                    <p>Akt</p>
                  </PartnerStatusElement>
                  <PartnerStatusElement
                    color={contractsOfPartner.find(c => c.status === ContractType.NEW || c.status === ContractType.ACCEPTED) ? "green" : "orange"}>
                    <div />
                    <p>Szerz.</p>
                  </PartnerStatusElement>
                  <PartnerStatusElement
                    color={contractsOfPartner.find(c => c.status === ContractType.ACCEPTED) ? "green" : "orange"}>
                    <div />
                    <p>Sz.OK</p>
                  </PartnerStatusElement>
                  {/*<PartnerStatusElement color={"blue"}>*/}
                  {/*  <div/>*/}
                  {/*  <p>Étlap</p>*/}
                  {/*</PartnerStatusElement>*/}
                  <PartnerStatusElement color={p.hoursModified ? "green" : "orange"}>
                    <div />
                    <p>NyT</p>
                  </PartnerStatusElement>
                  <PartnerStatusElement color={p.shippingPricesModified ? "green" : "orange"}>
                    <div />
                    <p>Száll</p>
                  </PartnerStatusElement>
                  <PartnerStatusElement
                    color={p.registered && !p.activateEmailPassphrase && /*TODO SZERZŐDÉS OK && */ p.list ? "green" : "orange"}>
                    <div />
                    <p>Listáz</p>
                  </PartnerStatusElement>
                </PartnerStatuses>
                {p.deactivated &&
                <PartnerDeactivated>
                  <FaExclamation />
                  <p>Inaktív</p>
                </PartnerDeactivated>
                }
                <PartnerActions>
                  <PartnerActionButton onClick={() => this.initiateCall(p.publicTel)}>
                    <FiPhoneCall />
                  </PartnerActionButton>
                  <PartnerActionButton onClick={() => this.loginToPartner()}>
                    <AiOutlineLogin />
                  </PartnerActionButton>
                </PartnerActions>
              </Partner>
            );
          })}
        </PartnersWrapper>
      </Wrapper>
    );
  }

  addPartnerProfile() {

  }

  searchMatch(p: Shop, q: string) {
    if (q === "")
      return true;

    //TODO countryCode --> phone number
    //TODO get city, zip from backend

    q = q.toLocaleLowerCase();
    let phoneNumber: PhoneNumber = parsePhoneNumberFromString(q, "HU");
    let telP: PhoneNumber = parsePhoneNumberFromString(p.publicTel, "HU");
    let city: City;
    let zip: ZipCode;
    return p.primaryContact.firstName.toLocaleLowerCase().includes(q) ||
      p.primaryContact.lastName.toLocaleLowerCase().includes(q) ||
      p.publicTel.toLocaleLowerCase().includes(q) ||
      p.name.toLocaleLowerCase().includes(q) ||
      p.address.street.toLocaleLowerCase().includes(q) ||
      p.publicEmail.toLocaleLowerCase().includes(q) ||
      //city.toLocaleLowerCase().includes(q) ||
      //zip.toLocaleLowerCase().includes(q) ||
      p.url.toLocaleLowerCase().includes(q) ||
      (!isNaN(parseInt(q)) && parseInt(q) === p.id) ||
      (phoneNumber?.isValid() && telP?.isValid() && phoneNumber?.number === telP?.number);
  }

  initiateCall(tel: number) {
    const phoneNumber: PhoneNumber = parsePhoneNumberFromString(tel, "HU");
    if (!phoneNumber)
      return;

    let standardNumber = phoneNumber.number;

    ZadarmaCall.setCallNumber(standardNumber);
    ZadarmaCall.callNumber();
  }

  loginToPartner() {
    let token = window.localStorage.getItem("usertoken");
    window.open(Config.partnerFrontendURL + "?token=" + token, "_blank");
  }
}
