export class AdminProfile {
  id: number;
  enabled: boolean;
  email: string;
  tel: string;
  firstName: string;
  lastName: string;
  comment: string;
  addedDate: Date;
  zadarmaSIP: string;
}
