export default function fill() {
  Date.prototype.toHunFormatMinutes = function () {
    let mm = this.getMonth() + 1; // getMonth() is zero-based
    let dd = this.getDate();
    let hh = this.getHours();
    let MM = this.getMinutes();

    return (
      this.getUTCFullYear() +
      "." +
      (mm > 9 ? "" : "0") +
      mm +
      "." +
      (dd > 9 ? "" : "0") +
      dd +
      ". " +
      (hh > 9 ? "" : "0") +
      hh +
      ":" +
      (MM > 9 ? "" : "0") +
      MM
    );
  };
}
