import React, {Component} from "react";
import styled from "styled-components";
import EventSystem from "../../utils/EventSystem";
import {Section} from "./Settings";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5%;
`;

export default class Tickets extends Component {
  state: {
    tickets: any[],
  } = {
    tickets: []
  };

  eventIDs = [];

  componentWillUnmount() {
    for (let eid of this.eventIDs) {
      EventSystem.unsubscribe(eid)
    }
  }

  componentDidMount() {
    this.eventIDs = [];
  }

  render() {
    return (
      <Wrapper>
        <Section>
          Hibajegyek
        </Section>
      </Wrapper>
    );
  }
}
