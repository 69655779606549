import APICaller from "./APICaller";
import { Shop } from "../../model/Shop";
import type { MessageBase } from "../ws/MessageHandler";

function getAll(quite: boolean, cb: (res: MessageBase & { shops: Shop[] | null })=>{}) {
  APICaller.ownFetch(quite, "POST", "/partners", {}, (data) => {
    let res = JSON.parse(data);
    if (cb)
      cb(res);
  });
}

export default {
  getAll
};
