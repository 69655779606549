import React, {Component} from "react";
import styled from "styled-components";
import {PageContainer} from "../components/PageContainer";
import EmailValidator from "../utils/EmailValidator";
import {toast} from "react-toastify";
import {AuthAPI} from "../utils/api/AuthAPI";
import {withRouter} from "react-router-dom";
import Modal from "../components/modals/Modal";
import {Helmet} from "react-helmet/es/Helmet";
import EventSystem from "../utils/EventSystem";
import ContextSystem from "../utils/ContextSystem";
import MessageType from "../utils/ws/MessageType";

const Wrapper = styled.div`
  width: 100%;
  min-height: ${({minheight}) => (minheight ? minheight : "70vh")};

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const LoginPanel = styled.div`
  width: 500px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px 1px dimgray;

  & > h1 {
    margin-bottom: 30px;
    font-family: "Segoe UI Light", sans-serif;
    font-size: 28pt;
  }

  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    & > p {
      margin: 0;
      padding: 0 0 0 5px;
      width: 70%;
      color: #cc2a2f;
      text-align: left;
      &:hover {
        cursor: pointer;
        color: #f33242;
      }
    }
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

export const Input = styled.input`
  width: ${({width}) => (width ? width : "70%")};
  padding: 5px 20px;
  margin: 10px 0;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;

  @media screen and (max-width: 600px) {
    width: ${({width}) => (width ? width : "100%")};
  }

  &:focus {
    border-color: rgba(147, 28, 32, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(147, 28, 32, 0.25);
  }
`;

export const Button = styled.div`
  border: 1px solid #931c20;
  border-radius: 5px;
  padding: 8px 20px;

  font-size: 12pt;
  font-family: "Raleway Black", sans-serif;
  margin: 5px 20% 15px 20%;
  text-align: center;
  transition: height 0.2s ease-in-out;
  color: #a62024;

  &:hover,
  &:active {
    background-color: #a62024;
    cursor: pointer;
    color: white;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12pt;
  }
`;

class LoginPage extends Component {
  eventIDs = [];

  state = {
    editEmail: "",
    editPass: "",
    showLostPassModal: false,
    editLostEmail: "",
  };

  loginOnEnter(e) {
    if (e.keyCode === 13)
      this.login();
  }

  login() {
    if (!EmailValidator(this.state.editEmail)) {
      toast("Email cím helytelen!");
      return;
    }
    AuthAPI.login(this.state.editEmail, this.state.editPass, (authenticated) => this.redirect(authenticated));
  }

  redirect(authenticated) {
    if (this.props.redirect) {
      if (authenticated)
        if (document.referrer === "") this.props.history.push("/");
        else this.props.history.goBack();
    }
  }

  sendLostPassRequest() {
    if (!EmailValidator(this.state.editLostEmail)) {
      toast("Hibás az email cím formátuma!");
      this.setState({editPass: ""});
      return;
    }
    AuthAPI.requestPasswordReset(this.state.editLostEmail, (res) => {
      if (res.error === MessageType.OK) {
        toast("A jelszó visszaállításához szükséges információt emailben elküldtük.");
        setTimeout(() => {
          this.setState({showLostPassModal: false});
        }, 500);
      } else {
        toast("Kérlek próbált újra később vagy írj nekünk egy emailt!");
      }
    });
  }

  sendLostPassOnEnter(e) {
    if (e.keyCode === 13)
      this.sendLostPassRequest();
  }

  componentWillUnmount() {
    for (let eventID of this.eventIDs) {
      EventSystem.unsubscribe(eventID);
    }
  }

  componentDidMount() {
    this.eventIDs = [];
    this.redirect(ContextSystem.loggedIn)
    let eid = EventSystem.subscribe(EventSystem.events.contextSystemChanged, ({loggedIn}) => {
      if (loggedIn !== undefined) {
        this.redirect(loggedIn);
      }
    });
    this.eventIDs.push(eid);
  }

  render() {
    return (
      <PageContainer>
        <Helmet>
          <title>EnniAkarok Partner Bejelentkezés</title>
          <meta name="description" content="Partner oldal bejelentkezés"/>
        </Helmet>
        <Wrapper>
          <LoginPanel>
            <h1>Bejelentkezés</h1>
            <form>
              <Input
                autoComplete={"email"}
                value={this.state.editEmail}
                onKeyUp={(e) => this.loginOnEnter(e)}
                type="email"
                placeholder="E-mail cím"
                onChange={(e) => this.setState({editEmail: e.target.value})}
              />
              <Input
                autoComplete={"password"}
                value={this.state.editPass}
                onKeyUp={(e) => this.loginOnEnter(e)}
                type="password"
                placeholder="Jelszó"
                onChange={(e) => this.setState({editPass: e.target.value})}
              />
              <p onClick={() => this.setState({showLostPassModal: true})}>Elfelejtettem a jelszavam</p>
              <Button onClick={() => this.login()}>Belépés</Button>
            </form>
          </LoginPanel>
        </Wrapper>
        <Modal show={this.state.showLostPassModal} onEscapeKeyDown={() => this.setState({showLostPassModal: false})}>
          <Modal.Header closeButton={() => this.setState({showLostPassModal: false})}>
            <Modal.Title>Jelszóemlékeztető küldése</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Kérlek add meg az email címedet. Ha nem emlékeszel rá, írj egy emailt, hogy tudjunk segíteni!</p>
            <Input
              value={this.state.editLostEmail}
              onKeyUp={(e) => this.sendLostPassOnEnter(e)}
              type="email"
              placeholder="Email cím"
              onChange={(e) => this.setState({editLostEmail: e.target.value})}
              width={"100%"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({showLostPassModal: false})}>
              Mégsem
            </Button>
            <Button variant="primary" onClick={() => this.sendLostPassRequest()}>
              Küldés
            </Button>
          </Modal.Footer>
        </Modal>
      </PageContainer>
    );
  }
}

export default withRouter(LoginPage);
