import React, { Component } from "react";
import logo from "../../assets/szimpla-logo_h100.png";
import logoText from "../../assets/logotext_h100.png";
import styled from "styled-components";

const NavigationLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  img:nth-child(1) {
    padding: 0 0 0 10px;
    height: 55px;
  }
  img {
    height: 50px;
    padding: 0;
    display: inline-block;
  }
`;

class HeaderLogo extends Component {
  render() {
    return (
      <NavigationLogo>
        <img alt={"logo"} src={logo} />
        <img alt={"logotext"} src={logoText} />
      </NavigationLogo>
    );
  }
}

export default HeaderLogo;
